import { Button, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import DropZone from "../components/dropZone.component";
import UploadFeedBack from "../components/uploadFeedBack.component";
import createOutputSheet from "../utils/createOutputSheet";
import masterFileGenerate from "../utils/masterFile";

const MasterReportPage = () => {
  const [processing, setProcessing] = React.useState(false);

  const [healthResponse, setHealthResponse] = React.useState(null);
  const [healthUpload, setHealthUpload] = React.useState(false);
  const handleHealth = (val) => {
    setHealthResponse(val);
  };

  const [salesResponse, setSalesResponse] = React.useState(null);
  const [salesUpload, setSalesUpload] = React.useState(false);
  const handleSales = (val) => {
    setSalesResponse(val);
  };

  const [inventoryResponse, setInventoryResponse] = React.useState(null);
  const [inventoryUpload, setInventoryUpload] = React.useState(false);
  const handleInventory = (val) => {
    setInventoryResponse(val);
  };

  const [vendorResponse, setVendorResponse] = React.useState(null);
  const [vendorUpload, setVendorUpload] = React.useState(false);
  const handleVendor = (val) => {
    setVendorResponse(val);
  };

  const [violationResponse, setViolationResponse] = React.useState(null);
  const [violationUpload, setViolationUpload] = React.useState(false);
  const handleViolation = (val) => {
    setViolationResponse(val);
  };

  const [orderResponse, setOrderResponse] = React.useState(null);
  const [orderUpload, setOrderUpload] = React.useState(false);
  const handleOrder = (val) => {
    setOrderResponse(val);
  };

  const [beResponse, setBeResponse] = React.useState(null);
  const [beUpload, setBeUpload] = React.useState(false);
  const handleBe = (val) => {
    setBeResponse(val);
  };

  const [fbaFeeResponse, setFbaFeeResponse] = React.useState(null);
  const [fbaFeeUpload, setFbaFeeUpload] = React.useState(false);
  const handleFbaFee = (val) => {
    setFbaFeeResponse(val);
  };

  const [fbaProductResponse, setFbaProductResponse] = React.useState(null);
  const [fbaProductUpload, setFbaProductUpload] = React.useState(false);
  const handleFbaProduct = (val) => {
    setFbaProductResponse(val);
  };

  const [feePreviewResponse, setFeePreviewResponse] = React.useState(null);
  const [feePreviewUpload, setFeePreviewUpload] = React.useState(false);
  const handleFeePreview = (val) => {
    setFeePreviewResponse(val);
  };

  const [snlResponse, setSnlResponse] = React.useState(null);
  const [snlUpload, setSnlUpload] = React.useState(false);
  const handleSnl = (val) => {
    setSnlResponse(val);
  };

  const [mrResponse, setMrResponse] = React.useState(null);
  const [mrUpload, setMrUpload] = React.useState(false);
  const handleMr = (val) => {
    setMrResponse(val);
  };

  const [pricingResponse, setPricingResponse] = React.useState(null);
  const [pricingUpload, setPricingUpload] = React.useState(false);
  const handlePricing = (val) => {
    setPricingResponse(val);
  };

  const [masterFile, setMasterFile] = React.useState(null);

  const handleMasterFile = (val) => {
    setMasterFile(val);
    createOutputSheet(val.firstArray, "master_file");
    if (val.secondArray) {
      createOutputSheet(val.secondArray, "fba_fee_export", true);
    }
  };

  React.useEffect(() => {
    if (healthResponse) console.log("HEALTH:", healthResponse);
    if (salesResponse) console.log("SALES:", salesResponse);
    if (inventoryResponse) console.log("INVENTORY:", inventoryResponse);
    if (vendorResponse) console.log("VENDOR:", vendorResponse);
    if (violationResponse) console.log("VIOLATION:", violationResponse);
    if (orderResponse) console.log("ORDER:", orderResponse);
    if (beResponse) console.log("BE:", beResponse);
    if (fbaFeeResponse) console.log("FBA FEE:", fbaFeeResponse);
    if (fbaProductResponse) console.log("FBA Product:", fbaProductResponse);
    if (feePreviewResponse) console.log("FEE Preview:", feePreviewResponse);
    if (snlResponse) console.log("SNL:", snlResponse);
    if (mrResponse) console.log("MR:", mrResponse);
    if (pricingResponse) console.log("PRICING:", pricingResponse);
    if (masterFile) console.log("FINAL:", masterFile);
  }, [
    orderResponse,
    salesResponse,
    masterFile,
    snlResponse,
    healthResponse,
    inventoryResponse,
    vendorResponse,
    violationResponse,
    beResponse,
    fbaFeeResponse,
    fbaProductResponse,
    feePreviewResponse,
    mrResponse,
    pricingResponse,
  ]);

  return (
    <Paper
      style={{
        width: "100%",
        minHeight: "100vh",
        padding: "10px 15px 50px 15px",
      }}
    >
      <Typography component="h1" variant="h4" gutterBottom>
        Master File Generation
      </Typography>
      <Grid container spacing={2} justify="center">
        <Grid item xs={4}>
          <DropZone
            name="Previous Master Report*"
            getResponse={handleMr}
            uploading={mrUpload}
            setUpload={(val) => setMrUpload(val)}
          />
          <UploadFeedBack responseObject={mrResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="Inventory Health File"
            getResponse={handleHealth}
            uploading={healthUpload}
            setUpload={(val) => setHealthUpload(val)}
          />
          <UploadFeedBack responseObject={healthResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="Lifetime Sales File"
            getResponse={handleSales}
            uploading={salesUpload}
            setUpload={(val) => setSalesUpload(val)}
          />
          <UploadFeedBack responseObject={salesResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="Manage Inventory File*"
            getResponse={handleInventory}
            uploading={inventoryUpload}
            setUpload={(val) => setInventoryUpload(val)}
          />
          <UploadFeedBack responseObject={inventoryResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="Manage Vendor File*"
            getResponse={handleVendor}
            uploading={vendorUpload}
            setUpload={(val) => setVendorUpload(val)}
          />
          <UploadFeedBack responseObject={vendorResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="Map Violation Vendor File"
            getResponse={handleViolation}
            uploading={violationUpload}
            setUpload={(val) => setViolationUpload(val)}
          />
          <UploadFeedBack responseObject={violationResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="BE Module File*"
            getResponse={handleBe}
            uploading={beUpload}
            setUpload={(val) => setBeUpload(val)}
          />
          <UploadFeedBack responseObject={beResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="Master SNL File*"
            getResponse={handleSnl}
            uploading={snlUpload}
            setUpload={(val) => setSnlUpload(val)}
          />
          <UploadFeedBack responseObject={snlResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="All Order File"
            getResponse={handleOrder}
            uploading={orderUpload}
            setUpload={(val) => setOrderUpload(val)}
          />
          <UploadFeedBack responseObject={orderResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="Fee Preview Report*"
            getResponse={handleFeePreview}
            uploading={feePreviewUpload}
            setUpload={(val) => setFeePreviewUpload(val)}
          />
          <UploadFeedBack responseObject={feePreviewResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="FBA Fee Preview Master*"
            getResponse={handleFbaFee}
            uploading={fbaFeeUpload}
            setUpload={(val) => setFbaFeeUpload(val)}
          />
          <UploadFeedBack responseObject={fbaFeeResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="7 Day Pricing File*"
            getResponse={handlePricing}
            uploading={pricingUpload}
            setUpload={(val) => setPricingUpload(val)}
          />
          <UploadFeedBack responseObject={pricingResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="Product Master Table*"
            getResponse={handleFbaProduct}
            uploading={fbaProductUpload}
            setUpload={(val) => setFbaProductUpload(val)}
          />
          <UploadFeedBack responseObject={fbaProductResponse} />
        </Grid>
        <Grid
          container
          spacing={3}
          justify="center"
          style={{ marginTop: "10px" }}
        >
          {processing ? (
            <Typography color="primary" variant="h5">
              Processing...
            </Typography>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={
                processing ||
                !inventoryResponse ||
                inventoryResponse?.missingColumns?.length > 0 ||
                !beResponse ||
                beResponse?.missingColumns?.length > 0 ||
                !vendorResponse ||
                vendorResponse?.missingColumns?.length > 0 ||
                !fbaFeeResponse ||
                fbaFeeResponse?.missingColumns?.length > 0 ||
                !feePreviewResponse ||
                feePreviewResponse?.missingColumns?.length > 0 ||
                !fbaProductResponse ||
                fbaProductResponse?.missingColumns?.length > 0 ||
                !snlResponse ||
                snlResponse?.missingColumns?.length > 0 ||
                !mrResponse ||
                mrResponse?.missingColumns?.length > 0 ||
                !pricingResponse ||
                pricingResponse?.missingColumns?.length > 0
              }
              onClick={() => {
                setProcessing(true);

                let response;

                setTimeout(function () {
                  response = masterFileGenerate(
                    orderResponse ? orderResponse.sheetToJson : null,
                    salesResponse ? salesResponse.sheetToJson : null,
                    salesResponse ? salesResponse.sheetToJson2 : null,
                    salesResponse ? salesResponse.sheetToJson3 : null,
                    snlResponse.sheetToJson,
                    healthResponse ? healthResponse.sheetToJson : null,
                    inventoryResponse.sheetToJson,
                    vendorResponse.sheetToJson,
                    violationResponse ? violationResponse.sheetToJson : null,
                    beResponse.sheetToJson,
                    fbaFeeResponse.sheetToJson,
                    fbaProductResponse.sheetToJson,
                    feePreviewResponse.sheetToJson,
                    pricingResponse.sheetToJson,
                    mrResponse.sheetToJson
                  );

                  setProcessing(false);

                  handleMasterFile(response);
                }, 1000);
              }}
            >
              Generate Master File
            </Button>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MasterReportPage;
