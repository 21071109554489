import React from "react";
import { Paper, Grid, Typography, Button } from "@material-ui/core";

import DropZone from "../components/dropZone.component";
import UploadFeedBack from "../components/uploadFeedBack.component";
import finalReportGenerate from "../utils/finalReport";
import createOutputSheet from "../utils/createOutputSheet";

const FinalReportPage = () => {
    const [masterReportResponse, setMasterreportResponse] = React.useState(null);
    const [fbaToolkitReportResponse, setFbaToolkitReportResponse] = React.useState(null);
    const [finalReport, setFinalReport] = React.useState(null);
    const [masterReportUpload, setMasterReportUpload] = React.useState(false);
    const [fbaToolkitReportUpload, setFbaToolkitReportUpload] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);

    const handleMasterReport = (val) => {
        setMasterreportResponse(val);
    };

    const handleFbaToolkitReport = (val) => {
        setFbaToolkitReportResponse(val);
    };

    const handleFinalReport = (val) => {
        setFinalReport(val);
        createOutputSheet(val.array, "final_report");
    };

    React.useEffect(() => {
        console.log("MASTER REPORT:", masterReportResponse);
        console.log("FBA TOOLKIT REPORT:", fbaToolkitReportResponse);
        console.log("FINAL:", finalReport);
    }, [finalReport, masterReportResponse, fbaToolkitReportResponse]);

    return (
        <Paper style={{ width: "100%", minHeight: "100vh", padding: "10px 15px 50px 15px" }}>
            <Typography component='h1' variant='h4' gutterBottom>
                Final Report Generation
            </Typography>
            <Grid container spacing={3} justify='center'>
                <Grid item xs={4}>
                    <DropZone
                        name='Master Report'
                        getResponse={handleMasterReport}
                        uploading={masterReportUpload}
                        setUpload={(val) => setMasterReportUpload(val)}
                    />
                    <UploadFeedBack responseObject={masterReportResponse} />
                </Grid>
                <Grid item xs={4}>
                    <DropZone
                        name='FBA Toolkit Report'
                        getResponse={handleFbaToolkitReport}
                        uploading={fbaToolkitReportUpload}
                        setUpload={(val) => setFbaToolkitReportUpload(val)}
                    />
                    <UploadFeedBack responseObject={fbaToolkitReportResponse} />
                </Grid>
            </Grid>
            <Grid container spacing={3} justify='center' style={{ marginTop: "10px" }}>
                {processing ? (
                    <Typography color='primary' variant='h5'>
                        Processing...
                    </Typography>
                ) : (
                    <Button
                        variant='contained'
                        color='primary'
                        size='large'
                        disabled={
                            processing ||
                            !masterReportResponse ||
                            masterReportResponse?.missingColumns?.length > 0 ||
                            !fbaToolkitReportResponse ||
                            fbaToolkitReportResponse?.missingColumns?.length > 0
                        }
                        onClick={() => {
                            setProcessing(true);

                            let response;

                            setTimeout(function () {
                                response = finalReportGenerate(
                                    masterReportResponse.sheetToJson,
                                    fbaToolkitReportResponse.sheetToJson
                                );

                                setProcessing(false);

                                handleFinalReport(response);
                            }, 1000);
                        }}>
                        Generate Final Report
                    </Button>
                )}
            </Grid>
        </Paper>
    );
};

export default FinalReportPage;
