import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  CssBaseline,
  Drawer,
  Divider,
  List,
  Toolbar,
  Typography,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Tooltip,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PublishIcon from "@material-ui/icons/Publish";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ReorderIcon from "@material-ui/icons/Reorder";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import MasterFile from "../pages/masterFile.page";
import ReorderFile from "../pages/reorderFile.page";
import MasterReport from "../pages/masterReport.page";
import FinalReport from "../pages/finalReport.page";
import MultiToolUpload from "../pages/multiToolUpload.page";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#ffffff",
    color: "#000000",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    ...theme.mixins.toolbar,
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },
}));

const MiniDrawer = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [location, setLocation] = React.useState(
    props.history.location.pathname
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <Grid container alignItems="center">
              <IconButton
                aria-label="open drawer"
                color="primary"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
              <Grid item>
                <img
                  alt="logo"
                  src={require("../assets/ergode.png")}
                  style={{
                    margin: "0 auto",
                    height: "50px",
                  }}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton color="secondary" onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon fontSize="large" />
              ) : (
                <ChevronLeftIcon fontSize="large" />
              )}
            </IconButton>
          </div>
          <Divider />
          <List
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            {[
              {
                name: (
                  <Typography variant="h6" color="primary">
                    Master File
                  </Typography>
                ),
                url: "/dashboard/master",
                icon: (
                  <Tooltip title="Master File Generation">
                    <DescriptionOutlinedIcon color="primary" fontSize="large" />
                  </Tooltip>
                ),
              },
              {
                name: (
                  <Typography variant="h6" color="primary">
                    Reorder File
                  </Typography>
                ),
                url: "/dashboard/reorder",
                icon: (
                  <Tooltip title="Reorder File Sorting">
                    <ReorderIcon color="primary" fontSize="large" />
                  </Tooltip>
                ),
              },
              {
                name: (
                  <Typography variant="h6" color="primary">
                    Master Report
                  </Typography>
                ),
                url: "/dashboard/reports",
                icon: (
                  <Tooltip title="Master Report Generation">
                    <AssessmentOutlinedIcon color="primary" fontSize="large" />
                  </Tooltip>
                ),
              },
              {
                name: (
                  <Typography variant="h6" color="primary">
                    Final Report
                  </Typography>
                ),
                url: "/dashboard/final",

                icon: (
                  <Tooltip title="Final Report Generation">
                    <AssessmentIcon color="primary" fontSize="large" />
                  </Tooltip>
                ),
              },
              {
                name: (
                  <Typography variant="h6" color="primary">
                    Multi-Tool Upload
                  </Typography>
                ),
                url: "/dashboard/upload",
                icon: (
                  <Tooltip title="Multi-Tool Upload">
                    <PublishIcon color="primary" fontSize="large" />
                  </Tooltip>
                ),
              },
              {
                name: (
                  <Typography variant="h6" color="secondary">
                    Log Out
                  </Typography>
                ),
                url: "/login",
                icon: (
                  <Tooltip title="Log Out">
                    <ExitToAppIcon color="secondary" fontSize="large" />
                  </Tooltip>
                ),
              },
            ].map((e, index) => (
              <Link
                key={e.url}
                to={e.url}
                style={{
                  textDecoration: "none",
                  marginTop: e.url === "/login" ? "auto" : 0,
                }}
                onClick={() => {
                  if (e.url === "/login") {
                    localStorage.removeItem("loggedIn");
                    props.history.push(`/login`);
                  } else {
                    setLocation(e.url);
                  }
                }}
              >
                <ListItem
                  button
                  key={e.name}
                  style={{ color: "black" }}
                  selected={location === e.url}
                >
                  <ListItemIcon>{e.icon}</ListItemIcon>
                  <ListItemText primary={e.name} />
                </ListItem>
              </Link>
            ))}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route path="/dashboard/master">
              <MasterFile />
            </Route>
            <Route path="/dashboard/reorder">
              <ReorderFile />
            </Route>
            <Route path="/dashboard/reports">
              <MasterReport />
            </Route>
            <Route path="/dashboard/final">
              <FinalReport />
            </Route>
            <Route path="/dashboard/upload">
              <MultiToolUpload />
            </Route>
          </Switch>
        </main>
      </div>
    </Router>
  );
};

export default MiniDrawer;
