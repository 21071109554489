import React from "react";
import { Grid, Button, TextField, Typography, ButtonGroup } from "@material-ui/core";
import ChipInput from "material-ui-chip-input";

import masterReportGenerate from "../utils/masterReport";
// import salesReportGenerate from "../utils/salesReport";
import constructSalesData from "../utils/constructSalesData";

const Form = ({
    handleFinalReport,
    handleSalesReport,
    masterResponse,
    reorderResponse,
    salesResponse,
    mfiResponse,
    snlResponse,
}) => {
    const [prefixes, setPrefixes] = React.useState([]);
    const [remarks, setRemarks] = React.useState([
        "active fba sku",
        "active fba skus",
        "n/a",
        "na",
        "n.a",
        "#na",
        "#n/a",
        "active listing",
    ]);
    const [wrknQty, setWrknQty] = React.useState(3);
    const [shpdQty, setShpdQty] = React.useState(3);
    const [rcvnQty, setRcvnQty] = React.useState(12);
    const [processing, setProcessing] = React.useState(false);

    return (
        <Grid
            container
            spacing={3}
            alignItems='center'
            justify='center'
            style={{ marginTop: "50px" }}>
            <Grid item xs={4}>
                <TextField
                    label='Minimum WRKN-QTY'
                    variant='outlined'
                    fullWidth
                    value={wrknQty}
                    onChange={(e) => setWrknQty(e.target.value)}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label='Minimum SHPD-QTY'
                    variant='outlined'
                    fullWidth
                    value={shpdQty}
                    onChange={(e) => setShpdQty(e.target.value)}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label='Minimum RCVN-QTY'
                    variant='outlined'
                    fullWidth
                    value={rcvnQty}
                    onChange={(e) => setRcvnQty(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <ChipInput
                    label='Add Remarks Filters'
                    variant='outlined'
                    fullWidth
                    value={remarks}
                    onAdd={(chip) => {
                        setRemarks([...remarks, chip]);
                    }}
                    onDelete={(chip) => {
                        setRemarks(remarks.filter((el) => el !== chip));
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <ChipInput
                    label='Add Vendor Prefixes'
                    variant='outlined'
                    fullWidth
                    value={prefixes}
                    onAdd={(chip) => {
                        setPrefixes([...prefixes, ...new Set(chip.split(" "))]);
                    }}
                    onDelete={(chip) => {
                        setPrefixes(prefixes.filter((el) => el !== chip));
                    }}
                />
            </Grid>
            <Grid item>
                {processing ? (
                    <Typography color='primary' variant='h5'>
                        Processing...
                    </Typography>
                ) : (
                    <ButtonGroup variant='contained' color='primary' size='large'>
                        <Button
                            disabled={
                                processing ||
                                !masterResponse ||
                                masterResponse?.missingColumns?.length > 0
                            }
                            onClick={() => {
                                setProcessing(true);

                                let response;

                                setTimeout(function () {
                                    response = masterReportGenerate(
                                        masterResponse.sheetToJson,
                                        reorderResponse ? reorderResponse.sheetToJson : null,
                                        salesResponse ? salesResponse.sheetToJson : null,
                                        mfiResponse ? mfiResponse.sheetToJson : null,
                                        snlResponse ? snlResponse.sheetToJson : null,
                                        prefixes,
                                        remarks,
                                        wrknQty,
                                        shpdQty,
                                        rcvnQty
                                    );

                                    setProcessing(false);

                                    handleFinalReport(response);
                                }, 1000);
                            }}>
                            Generate Master Report
                        </Button>
                        <Button
                            disabled={
                                processing ||
                                !salesResponse ||
                                salesResponse?.missingColumns?.length > 0
                            }
                            onClick={() => {
                                setProcessing(true);

                                let response;

                                setTimeout(function () {
                                    response = constructSalesData(salesResponse.sheetToJson);

                                    setProcessing(false);

                                    handleSalesReport(response);
                                }, 1000);
                            }}>
                            Generate Sales Report
                        </Button>
                    </ButtonGroup>
                )}
            </Grid>
        </Grid>
    );
};

export default Form;
