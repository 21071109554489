import constructSalesData from "./constructSalesData";

const finalReport = (
    master,
    reorder,
    sales,
    mfi,
    snl,
    prefixes,
    remarks,
    wrknQty,
    shpdQty,
    rcvnQty
) => {
    let firstArray = [],
        secondArray = [];

    // step 1 - filter out "Listing Price" <= "Breakeven" and move to second sheet
    master.forEach((el) => {
        if (
            Number(el["Listing Price"]) < Number(el["Breakeven"]) &&
            Number(el["Total Inventory"]) >= 1
        ) {
            secondArray.push(el);
        } else {
            firstArray.push(el);
        }
    });

    // step 2 - filter out "Total Aging" >= 1 and move to second sheet
    firstArray.forEach((el, index) => {
        if (Number(el["Total ageing Inventory"]) >= 1) {
            secondArray.push(el);
        }
    });

    firstArray = firstArray.filter((item) => !(Number(item["Total ageing Inventory"]) >= 1));

    // step 3 - lookup "ASIN" in firstArray with "ASIN" in secondArray and filter the matches
    const secondArrayAsins = secondArray.map((el) => el["ASIN"]);

    firstArray = firstArray.filter((item) => !secondArrayAsins.includes(item["ASIN"]));

    // step 4 - filter out "SKU Status" other than Active and blanks
    firstArray = firstArray.filter(
        (item) => !item["SKU Status"] || item["SKU Status"] === "Active"
    );

    // step 5 - filter "Remarks"
    if (remarks.length > 0) {
        firstArray = firstArray.filter(
            (item) =>
                !item["Remarks"] ||
                remarks.some((remark) => new RegExp("^" + remark, "i").test(item["Remarks"]))
        );
    }

    // step 6 - filter out "AFN-INBND-WRKN-QTY" 0 to 3
    firstArray = firstArray.filter((item) => !(Number(item["AFN-INBND-WRKN-QTY"]) > wrknQty));

    // step 7 - filter out "AFN-INBND-SHPD-QTY" 0 to 3
    firstArray = firstArray.filter((item) => !(Number(item["AFN-INBND-SHPD-QTY"]) > shpdQty));

    // step 8 - filter out "AFN-INBND-RCVN-QTY" 0 to 12
    firstArray = firstArray.filter((item) => !(Number(item["AFN-INBND-RCVN-QTY"]) > rcvnQty));

    // step 9 - new column with addition of "AFN-FUL-QTY" and "AFN-RSRVD-QTY"
    // not needed
    // firstArray = firstArray.map((item) => ({
    //     ...item,
    //     "AFN-FUL-QTY + AFN-RSRVD-QTY": item["AFN-FUL-QTY"] + item["AFN-RSRVD-QTY"],
    // }));

    // step 9 - If 30 days sales = 0, Ful+Res >= 1, Delete
    firstArray = firstArray.filter(
        (item) =>
            !(
                Number(item["30 days"]) === 0 &&
                Number(item["AFN-FUL-QTY"]) + Number(item["AFN-RSRVD-QTY"]) >= 1
            )
    );

    // step 10 - If 15 days sales = 0, Ful+Res >= 1, Delete
    firstArray = firstArray.filter(
        (item) =>
            !(
                Number(item["15 days"]) === 0 &&
                Number(item["AFN-FUL-QTY"]) + Number(item["AFN-RSRVD-QTY"]) >= 1
            )
    );

    // step 11 - If 7 days sales = 0, Ful+Res >= 1, Delete
    firstArray = firstArray.filter(
        (item) =>
            !(
                Number(item["7 days"]) === 0 &&
                Number(item["AFN-FUL-QTY"]) + Number(item["AFN-RSRVD-QTY"]) >= 1
            )
    );

    // step 12 - If 3 days sales = 0, Ful+Res >=9, Delete
    firstArray = firstArray.filter(
        (item) =>
            !(
                Number(item["3 days"]) === 0 &&
                Number(item["AFN-FUL-QTY"]) + Number(item["AFN-RSRVD-QTY"]) >= 9
            )
    );

    //  step 13 - If Ful+ reserve >= 300 delete
    firstArray = firstArray.filter(
        (item) => !(Number(item["AFN-FUL-QTY"]) + Number(item["AFN-RSRVD-QTY"]) >= 300)
    );

    // step 14 - 7 days sales value mapper filter
    const sevenDaysMap = {
        1: 8,
        2: 11,
        3: 12,
        4: 16,
        5: 20,
        6: 24,
        7: 28,
        8: 32,
        9: 40,
    };

    for (let i = 1; i <= 60; i++) {
        if (i <= 9) {
            firstArray = firstArray.filter(
                (item) =>
                    !(
                        Number(item["7 days"]) === i &&
                        Number(item["AFN-FUL-QTY"]) + Number(item["AFN-RSRVD-QTY"]) >=
                            sevenDaysMap[i]
                    )
            );
        } else if (i >= 10 && i <= 20) {
            firstArray = firstArray.filter(
                (item) =>
                    !(
                        Number(item["7 days"]) === i &&
                        Number(item["AFN-FUL-QTY"]) + Number(item["AFN-RSRVD-QTY"]) >= i * 5
                    )
            );
        } else if (i >= 21 && i <= 24) {
            firstArray = firstArray.filter(
                (item) =>
                    !(
                        Number(item["7 days"]) === i &&
                        Number(item["AFN-FUL-QTY"]) + Number(item["AFN-RSRVD-QTY"]) >= 110
                    )
            );
        } else if (i >= 25 && i <= 28) {
            firstArray = firstArray.filter(
                (item) =>
                    !(
                        Number(item["7 days"]) === i &&
                        Number(item["AFN-FUL-QTY"]) + Number(item["AFN-RSRVD-QTY"]) >= 120
                    )
            );
        } else if (i >= 29 && i <= 33) {
            firstArray = firstArray.filter(
                (item) =>
                    !(
                        Number(item["7 days"]) === i &&
                        Number(item["AFN-FUL-QTY"]) + Number(item["AFN-RSRVD-QTY"]) >= 150
                    )
            );
        } else if (i >= 34 && i <= 40) {
            firstArray = firstArray.filter(
                (item) =>
                    !(
                        Number(item["7 days"]) === i &&
                        Number(item["AFN-FUL-QTY"]) + Number(item["AFN-RSRVD-QTY"]) >= 200
                    )
            );
        } else if (i >= 41 && i <= 50) {
            firstArray = firstArray.filter(
                (item) =>
                    !(
                        Number(item["7 days"]) === i &&
                        Number(item["AFN-FUL-QTY"]) + Number(item["AFN-RSRVD-QTY"]) >= 250
                    )
            );
        } else if (i >= 51 && i <= 60) {
            firstArray = firstArray.filter(
                (item) =>
                    !(
                        Number(item["7 days"]) === i &&
                        Number(item["AFN-FUL-QTY"]) + Number(item["AFN-RSRVD-QTY"]) >= 280
                    )
            );
        }
    }

    // // step 14 - (Qty Sales(7)/7)15 and filter >= "Total Inventory"
    // firstArray = firstArray.filter((item) => (item["7 days"] / 7) * 15 >= item["Total Inventory"]);

    // // step 15 - (Qty Sales(15)/15)30 and filter >= "Total Inventory"
    // firstArray = firstArray.filter(
    //     (item) => (item["15 days"] / 15) * 30 >= item["Total Inventory"]
    // );

    // // step 16 - "Total Inventory"/Qty Sales(30) and filter <= 50%
    // firstArray = firstArray.filter(
    //     (item) => item["30 days"] === 0 || (item["Total Inventory"] / item["30 days"]) * 100 <= 50
    // );

    // step 17 - filter out "Net Price" > 50 & "Total Inventory" > 10
    firstArray = firstArray.filter(
        (item) => !(Number(item["Net Price"]) >= 50 && Number(item["Total Inventory"]) >= 10)
    );

    // step 18 - filter with vendor prefix and inputs
    if (prefixes.length > 0) {
        firstArray = firstArray.filter((item) =>
            prefixes.some((prefix) => new RegExp("^" + prefix, "i").test(item["Prefix"]))
        );
    }

    // step 19 - match reorder SKUs and master SKUs and add corrsponding comments in a new column
    if (reorder) {
        firstArray = firstArray.map((item) => ({
            ...item,
            "Re-order Recommendation": reorder.filter((el) => item["SKU"] === el["SKU"])[0]
                ? reorder.filter((el) => item["SKU"] === el["SKU"])[0]["Remarks"]
                : "",
        }));
    }

    // step 20 - strike rate data calculation
    if (sales) {
        const salesData = constructSalesData(sales);

        firstArray = firstArray.map((item) => ({
            ...item,
            "Grand Total": salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]
                ? salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]["Grand Total"]
                : "",
            "Sales Days": salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]
                ? salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]["Sales Days"]
                : "",
            "Strike Rate": salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]
                ? salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]["Strike Rate"]
                : "",
            ">SR": salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]
                ? salesData.filter((el) => el["Row Labels"] === item["SKU"])[0][">SR"]
                : "",
            "<SR": salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]
                ? salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]["<SR"]
                : "",
            "Avg>SR": salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]
                ? salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]["Avg>SR"]
                : "",
            "Avg<SR": salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]
                ? salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]["Avg<SR"]
                : "",
            OS: salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]
                ? salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]["OS"]
                : "",
            CS: salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]
                ? salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]["CS"]
                : "",
            AS: salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]
                ? salesData.filter((el) => el["Row Labels"] === item["SKU"])[0]["AS"]
                : "",
        }));
    }

    if (mfi) {
        firstArray = firstArray.map((item) => {
            const foundAsins = mfi.filter((el) => el.asin === item["ASIN"]);
            const fbaAsinQty = foundAsins.reduce(
                (acc, el) => acc + Number(el["afn-total-quantity"]),
                0
            );

            if (foundAsins.length > 0) {
                return {
                    ...item,
                    "FBA ASIN Qty": fbaAsinQty,
                    "Diff between FBA ASIN Qty and Total Inventory":
                        fbaAsinQty - Number(item["Total Inventory"]),
                };
            } else {
                return {
                    ...item,
                    "FBA ASIN Qty": "",
                    "Diff between FBA ASIN Qty and Total Inventory": "",
                };
            }
        });
    }

    if (snl) {
        firstArray = firstArray.map((item) => {
            const foundAsins = snl.filter((el) => el.ASIN === item["ASIN"]);
            const snlAsinQty = foundAsins.reduce(
                (acc, el) => acc + Number(el["Inventory in SnL FC (units)"]),
                0
            );

            const [foundSku] = snl.filter((el) => el.SKU === item["SKU"]);

            if (foundAsins.length > 0) {
                if (foundSku) {
                    return {
                        ...item,
                        "SNL ASIN Qty": snlAsinQty,
                        "Diff between SNL ASIN Qty and Total Inventory":
                            snlAsinQty - Number(item["Total Inventory"]),
                        "SNL SKU Qty": foundSku["Inventory in SnL FC (units)"],
                        "Diff between SNL SKU Qty and Total Inventory":
                            Number(foundSku["Inventory in SnL FC (units)"]) -
                            Number(item["Total Inventory"]),
                    };
                } else {
                    return {
                        ...item,
                        "SNL ASIN Qty": snlAsinQty,
                        "Diff between SNL ASIN Qty and Total Inventory":
                            snlAsinQty - Number(item["Total Inventory"]),
                        "SNL SKU Qty": "",
                        "Diff between SNL SKU Qty and Total Inventory": "",
                    };
                }
            } else {
                if (foundSku) {
                    return {
                        ...item,
                        "SNL ASIN Qty": "",
                        "Diff between SNL ASIN Qty and Total Inventory": "",
                        "SNL SKU Qty": Number(foundSku["Inventory in SnL FC (units)"]),
                        "Diff between SNL SKU Qty and Total Inventory":
                            Number(foundSku["Inventory in SnL FC (units)"]) -
                            Number(item["Total Inventory"]),
                    };
                } else {
                    return {
                        ...item,
                        "SNL ASIN Qty": "",
                        "Diff between SNL ASIN Qty and Total Inventory": "",
                        "SNL SKU Qty": "",
                        "Diff between SNL SKU Qty and Total Inventory": "",
                    };
                }
            }
        });
    }

    // step 21 scrapper price "Net Price" or "Breakeven" whatever is higher
    firstArray = firstArray.map((item) => ({
        ...item,
        "Scrapper Price": item["Net Price"]
            ? item["Breakeven"]
                ? Number(item["Net Price"]) >= Number(item["Breakeven"])
                    ? item["Net Price"]
                    : item["Breakeven"]
                : item["Net Price"]
            : 0,
    }));

    const firstArrayCopy = firstArray.map((item) => ({
        SKU: item["SKU"],
        Prefix: item["Prefix"],
        "Model#": item["Model#"],
        "Vendor Name": item["Vendor Name"],
        ASIN: item["ASIN"],
        UPC: item["UPC"],
        "Item Title": item["Item Title"],
        "Master Pack": item["Master Pack"],
        "Inner Pack": item["Inner Pack"],
        "Gross Price": item["Gross Price"],
        "Discount(%)": item["Discount(%)"],
        "Net Price": item["Net Price"],
        Quantity: "",
        Amount: "",
        "Re-order Recommendation": item["Re-order Recommendation"],
        "Differencof Cost and Net price": item["Differencof Cost and Net price"],
        "Item Brand": item["Item Brand"],
        "SKU Status": item["SKU Status"],
        "Last Modified by": item["Last Modified by"],
        "Inactivity End Date": item["Inactivity End Date"],
        Remarks: item["Remarks"],
        "MOV AMOUNT": item["MOV AMOUNT"],
        "MOV-FREE SHIPPING": item["MOV-FREE SHIPPING"],
        "MOV DISCOUNT": item["MOV DISCOUNT"],
        "Loss Selling": item["Loss Selling"],
        "Grand Total": item["Grand Total"],
        "Sales Days": item["Sales Days"],
        "Strike Rate": item["Strike Rate"],
        ">SR": item[">SR"],
        "<SR": item["<SR"],
        "Avg>SR": item["Avg>SR"],
        "Avg<SR": item["Avg<SR"],
        OS: item["OS"],
        CS: item["CS"],
        AS: item["AS"],
        "Lifetime Qty Sales": item["Lifetime Qty Sales"],
        "SALES 2019": item["SALES 2019"],
        "Current year sales": item["Current year sales"],
        "30 days": item["30 days"],
        "15 days": item["15 days"],
        "7 days": item["7 days"],
        "3 days": item["3 days"],
        "1 day": item["1 day"],
        value: item["value"],
        count: item["count"],
        "Sales Rank": item["Sales Rank"],
        "BuyBox Price/Landed Price": item["BuyBox Price/Landed Price"],
        "Prep Instruction": item["Prep Instruction"],
        "AFN-FUL-QTY": item["AFN-FUL-QTY"],
        "AFN-RSRVD-QTY": item["AFN-RSRVD-QTY"],
        "AFN-INBND-WRKN-QTY": item["AFN-INBND-WRKN-QTY"],
        "AFN-INBND-SHPD-QTY": item["AFN-INBND-SHPD-QTY"],
        "AFN-INBND-RCVN-QTY": item["AFN-INBND-RCVN-QTY"],
        "Total Inventory": item["Total Inventory"],
        "FBA ASIN Qty": item["FBA ASIN Qty"],
        "Diff between FBA ASIN Qty and Total Inventory":
            item["Diff between FBA ASIN Qty and Total Inventory"],
        "SNL ASIN Qty": item["SNL ASIN Qty"],
        "Diff between SNL ASIN Qty and Total Inventory":
            item["Diff between SNL ASIN Qty and Total Inventory"],
        "SNL SKU Qty": item["SNL SKU Qty"],
        "Diff between SNL SKU Qty and Total Inventory":
            item["Diff between SNL SKU Qty and Total Inventory"],
        OFFERS: item["OFFERS"],
        "ADDITIONAL INFO": item["ADDITIONAL INFO"],
        "Parent Child Info": item["Parent Child Info"],
        "Total Cost": item["Total Cost"],
        "Total Cost after adding extra charges": item["Total Cost after adding extra charges"],
        "FBA Cost": item["FBA Cost"],
        "Amz Commission %": item["Amz Commission %"],
        MAP: item["MAP"],
        "MAP as per BE": item["MAP as per BE"],
        Floor: item["Floor"],
        Ceiling: item["Ceiling"],
        "Listing Price": item["Listing Price"],
        Breakeven: item["Breakeven"],
        "NEW BE AS PER COST VARIANCE": item["NEW BE AS PER COST VARIANCE"],
        "P/L": item["P/L"],
        "P/L%": item["P/L%"],
        "Inventory Age 0 To 90 Days": item["Inventory Age 0 To 90 Days"],
        "Inventory Age 91 To 180 Days": item["Inventory Age 91 To 180 Days"],
        "Inventory Age 181 To 270 Days": item["Inventory Age 181 To 270 Days"],
        "Inventory Age 271 To 365 Days": item["Inventory Age 271 To 365 Days"],
        "Inventory Age 365 PLUS Days": item["Inventory Age 365 PLUS Days"],
        "Total ageing Inventory": item["Total ageing Inventory"],
        "Scrapper Price": item["Scrapper Price"],
        "Product Category Id": item["Product Category Id"],
        "LTSF SUM": item["LTSF SUM"],
        "LTSF QTY": item["LTSF QTY"],
        "Remarks Pricing": item["Remarks Pricing"],
        "FOLLWING MAP": item["FOLLWING MAP"],
    }));

    return {
        firstArray: firstArrayCopy,
        secondArray,
    };
};

export default finalReport;
