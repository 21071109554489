const uploadMasterData = async (data) => {
    const postData = data.map((el) => ({
        sku: el["SKU"],
        asin: el["ASIN"],
        vendorName: el["Vendor Name"],
        breakEven: el["Breakeven"],
    }));

    return postData;
};

export default uploadMasterData;
