import React from "react";
import { Paper, Grid, Typography, Button } from "@material-ui/core";

import DropZone from "../components/dropZone.component";
import UploadFeedBack from "../components/uploadFeedBack.component";
import createOutputSheets from "../utils/createOutputSheets";
import reorderFileGenerate from "../utils/reorderFile";

const MasterReportPage = () => {
  const [processing, setProcessing] = React.useState(false);

  const [masterResponse, setMasterResponse] = React.useState(null);
  const [masterUpload, setMasterUpload] = React.useState(false);
  const handleMaster = (val) => {
    setMasterResponse(val);
  };

  const [fbaInvResponse, setFbaInvResponse] = React.useState(null);
  const [fbaInvUpload, setFbaInvUpload] = React.useState(false);
  const handleFbaInv = (val) => {
    setFbaInvResponse(val);
  };

  const [snlInvResponse, setSnlInvResponse] = React.useState(null);
  const [snlInvUpload, setSnlInvUpload] = React.useState(false);
  const handleSnlInv = (val) => {
    setSnlInvResponse(val);
  };

  const [archiveInvResponse, setArchiveInvResponse] = React.useState(null);
  const [archiveInvUpload, setArchiveInvUpload] = React.useState(false);
  const handleArchiveInv = (val) => {
    setArchiveInvResponse(val);
  };

  const [pmtResponse, setPmtResponse] = React.useState(null);
  const [pmtUpload, setPmtUpload] = React.useState(false);
  const handlePmt = (val) => {
    setPmtResponse(val);
  };

  const [restrictedResponse, setRestrictedResponse] = React.useState(null);
  const [restrictedUpload, setRestrictedUpload] = React.useState(false);
  const handleRestricted = (val) => {
    setRestrictedResponse(val);
  };

  const [lifeSalesResponse, setLifeSalesResponse] = React.useState(null);
  const [lifeSalesUpload, setLifeSalesUpload] = React.useState(false);
  const handleLifeSales = (val) => {
    setLifeSalesResponse(val);
  };

  const [scrapperResponse, setScrapperResponse] = React.useState(null);
  const [scrapperUpload, setScrapperUpload] = React.useState(false);
  const handleScrapper = (val) => {
    setScrapperResponse(val);
  };

  const [reorderFile, setReorderFile] = React.useState(null);

  const handleReorderFile = (val) => {
    setReorderFile(val);
    createOutputSheets(
      val.firstArray,
      val.secondArray,
      "reorder_file",
      "reorder_FBA",
      "reorder_SNL"
    );
  };

  React.useEffect(() => {
    if (masterResponse) console.log("MASTER:", masterResponse);
    if (fbaInvResponse) console.log("FBA:", fbaInvResponse);
    if (snlInvResponse) console.log("SNL:", snlInvResponse);
    if (archiveInvResponse) console.log("ARCHIVE:", archiveInvResponse);
    if (pmtResponse) console.log("PMT:", pmtResponse);
    if (restrictedResponse) console.log("RESTRICTED:", restrictedResponse);
    if (lifeSalesResponse) console.log("SALES:", lifeSalesResponse);
    if (scrapperResponse) console.log("SALES:", scrapperResponse);
    if (reorderFile) console.log("FINAL:", reorderFile);
  }, [
    archiveInvResponse,
    fbaInvResponse,
    lifeSalesResponse,
    masterResponse,
    pmtResponse,
    reorderFile,
    restrictedResponse,
    snlInvResponse,
    scrapperResponse,
  ]);

  return (
    <Paper
      style={{
        width: "100%",
        minHeight: "100vh",
        padding: "10px 15px 50px 15px",
      }}
    >
      <Typography component="h1" variant="h4" gutterBottom>
        Reorder File Sorting
      </Typography>
      <Grid container spacing={2} justify="center">
        <Grid item xs={4}>
          <DropZone
            name="Master Report File*"
            getResponse={handleMaster}
            uploading={masterUpload}
            setUpload={(val) => setMasterUpload(val)}
          />
          <UploadFeedBack responseObject={masterResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="FBA Inventory File*"
            getResponse={handleFbaInv}
            uploading={fbaInvUpload}
            setUpload={(val) => setFbaInvUpload(val)}
          />
          <UploadFeedBack responseObject={fbaInvResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="SNL Inventory File*"
            getResponse={handleSnlInv}
            uploading={snlInvUpload}
            setUpload={(val) => setSnlInvUpload(val)}
          />
          <UploadFeedBack responseObject={snlInvResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="Archive Inventory File*"
            getResponse={handleArchiveInv}
            uploading={archiveInvUpload}
            setUpload={(val) => setArchiveInvUpload(val)}
          />
          <UploadFeedBack responseObject={archiveInvResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="PMT Export File*"
            getResponse={handlePmt}
            uploading={pmtUpload}
            setUpload={(val) => setPmtUpload(val)}
          />
          <UploadFeedBack responseObject={pmtResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="Restricted Brand File*"
            getResponse={handleRestricted}
            uploading={restrictedUpload}
            setUpload={(val) => setRestrictedUpload(val)}
          />
          <UploadFeedBack responseObject={restrictedResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="Lifetime Sales File"
            getResponse={handleLifeSales}
            uploading={lifeSalesUpload}
            setUpload={(val) => setLifeSalesUpload(val)}
          />
          <UploadFeedBack responseObject={lifeSalesResponse} />
        </Grid>
        <Grid item xs={4}>
          <DropZone
            name="Scrapper Output File*"
            getResponse={handleScrapper}
            uploading={scrapperUpload}
            setUpload={(val) => setScrapperUpload(val)}
          />
          <UploadFeedBack responseObject={scrapperResponse} />
        </Grid>
        <Grid
          container
          spacing={3}
          justify="center"
          style={{ marginTop: "10px" }}
        >
          {processing ? (
            <Typography color="primary" variant="h5">
              Processing...
            </Typography>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={
                processing ||
                !masterResponse ||
                masterResponse?.missingColumns?.length > 0 ||
                !fbaInvResponse ||
                fbaInvResponse?.missingColumns?.length > 0 ||
                !snlInvResponse ||
                snlInvResponse?.missingColumns?.length > 0 ||
                !archiveInvResponse ||
                archiveInvResponse?.missingColumns?.length > 0 ||
                !pmtResponse ||
                pmtResponse?.missingColumns?.length > 0 ||
                !restrictedResponse ||
                restrictedResponse?.missingColumns?.length > 0 ||
                !lifeSalesResponse ||
                lifeSalesResponse?.missingColumns?.length > 0 ||
                !scrapperResponse ||
                scrapperResponse?.missingColumns?.length > 0
              }
              onClick={() => {
                setProcessing(true);

                let response;

                setTimeout(function () {
                  response = reorderFileGenerate(
                    scrapperResponse ? scrapperResponse.sheetToJson : null,
                    masterResponse ? masterResponse.sheetToJson : null,
                    fbaInvResponse ? fbaInvResponse.sheetToJson : null,
                    snlInvResponse ? snlInvResponse.sheetToJson : null,
                    archiveInvResponse ? archiveInvResponse.sheetToJson : null,
                    pmtResponse ? pmtResponse.sheetToJson : null,
                    restrictedResponse ? restrictedResponse.sheetToJson : null,
                    lifeSalesResponse ? lifeSalesResponse.sheetToJson : null,
                    lifeSalesResponse ? lifeSalesResponse.sheetToJson2 : null,
                    lifeSalesResponse ? lifeSalesResponse.sheetToJson3 : null
                  );

                  setProcessing(false);

                  handleReorderFile(response);
                }, 1000);
              }}
            >
              Generate Reorder Sort File
            </Button>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MasterReportPage;
