function compare(a, b) {
  if (Number(a) < Number(b)) {
    return 1;
  }
  if (Number(a) > Number(b)) {
    return -1;
  }
  return 0;
}

const constructSalesCountData = (incomingData) => {
  const salesDaysArray = incomingData.map((el) => {
    const saleDate = new Date(el["purchase-date"].slice(0, 10)).getUTCDate();
    const sakeMonth = new Date(el["purchase-date"].slice(0, 10)).getUTCMonth();
    const saleYear = new Date(
      el["purchase-date"].slice(0, 10)
    ).getUTCFullYear();
    const saleDay = new Date(saleYear, sakeMonth, saleDate).getTime();

    return saleDay;
  });

  const uniqueSalesDays = [...new Set(salesDaysArray)].sort(compare);

  const salesMap = incomingData.reduce((acc, el) => {
    const saleDate = new Date(el["purchase-date"].slice(0, 10)).getUTCDate();
    const sakeMonth = new Date(el["purchase-date"].slice(0, 10)).getUTCMonth();
    const saleYear = new Date(
      el["purchase-date"].slice(0, 10)
    ).getUTCFullYear();
    const saleDay = new Date(saleYear, sakeMonth, saleDate).getTime();

    const skuIndex = acc.findIndex((item) => item["sku"] === el["sku"]);

    if (skuIndex === -1) {
      acc.push({
        sku: el["sku"],
        sales: {
          [saleDay]: Number(el["quantity"]),
        },
      });
    } else {
      if (acc[skuIndex].sales[saleDay]) {
        acc[skuIndex].sales[saleDay] += Number(el["quantity"]);
      } else {
        acc[skuIndex].sales[saleDay] = Number(el["quantity"]);
      }
    }

    return acc;
  }, []);

  return salesMap.map((item) => {
    const salesArray = Object.keys(item.sales);

    let salesData = {
      sku: item.sku,
      "30 days": "#N/A",
      "15 days": "#N/A",
      "7 days": "#N/A",
      "3 days": "#N/A",
      "1 day": "#N/A",
    };

    for (let i = 0; i < salesArray.length; i++) {
      if (uniqueSalesDays.findIndex((el) => el === Number(salesArray[i])) < 2) {
        salesData["1 day"] =
          salesData["1 day"] === "#N/A"
            ? 0 + Number(item.sales[salesArray[i]])
            : salesData["1 day"] + Number(item.sales[salesArray[i]]);
        salesData["3 days"] =
          salesData["3 days"] === "#N/A"
            ? 0 + Number(item.sales[salesArray[i]])
            : salesData["3 days"] + Number(item.sales[salesArray[i]]);
        salesData["7 days"] =
          salesData["7 days"] === "#N/A"
            ? 0 + Number(item.sales[salesArray[i]])
            : salesData["7 days"] + Number(item.sales[salesArray[i]]);
        salesData["15 days"] =
          salesData["15 days"] === "#N/A"
            ? 0 + Number(item.sales[salesArray[i]])
            : salesData["15 days"] + Number(item.sales[salesArray[i]]);
        salesData["30 days"] =
          salesData["30 days"] === "#N/A"
            ? 0 + Number(item.sales[salesArray[i]])
            : salesData["30 days"] + Number(item.sales[salesArray[i]]);
      } else if (
        uniqueSalesDays.findIndex((el) => el === Number(salesArray[i])) < 4
      ) {
        salesData["3 days"] =
          salesData["3 days"] === "#N/A"
            ? 0 + Number(item.sales[salesArray[i]])
            : salesData["3 days"] + Number(item.sales[salesArray[i]]);
        salesData["7 days"] =
          salesData["7 days"] === "#N/A"
            ? 0 + Number(item.sales[salesArray[i]])
            : salesData["7 days"] + Number(item.sales[salesArray[i]]);
        salesData["15 days"] =
          salesData["15 days"] === "#N/A"
            ? 0 + Number(item.sales[salesArray[i]])
            : salesData["15 days"] + Number(item.sales[salesArray[i]]);
        salesData["30 days"] =
          salesData["30 days"] === "#N/A"
            ? 0 + Number(item.sales[salesArray[i]])
            : salesData["30 days"] + Number(item.sales[salesArray[i]]);
      } else if (
        uniqueSalesDays.findIndex((el) => el === Number(salesArray[i])) < 8
      ) {
        salesData["7 days"] =
          salesData["7 days"] === "#N/A"
            ? 0 + Number(item.sales[salesArray[i]])
            : salesData["7 days"] + Number(item.sales[salesArray[i]]);
        salesData["15 days"] =
          salesData["15 days"] === "#N/A"
            ? 0 + Number(item.sales[salesArray[i]])
            : salesData["15 days"] + Number(item.sales[salesArray[i]]);
        salesData["30 days"] =
          salesData["30 days"] === "#N/A"
            ? 0 + Number(item.sales[salesArray[i]])
            : salesData["30 days"] + Number(item.sales[salesArray[i]]);
      } else if (
        uniqueSalesDays.findIndex((el) => el === Number(salesArray[i])) < 16
      ) {
        salesData["15 days"] =
          salesData["15 days"] === "#N/A"
            ? 0 + Number(item.sales[salesArray[i]])
            : salesData["15 days"] + Number(item.sales[salesArray[i]]);
        salesData["30 days"] =
          salesData["30 days"] === "#N/A"
            ? 0 + Number(item.sales[salesArray[i]])
            : salesData["30 days"] + Number(item.sales[salesArray[i]]);
      } else if (
        uniqueSalesDays.findIndex((el) => el === Number(salesArray[i])) < 31
      ) {
        salesData["30 days"] =
          salesData["30 days"] === "#N/A"
            ? 0 + Number(item.sales[salesArray[i]])
            : salesData["30 days"] + Number(item.sales[salesArray[i]]);
      }
    }

    return salesData;
  });
};

export default constructSalesCountData;
