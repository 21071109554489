const finalReport = (masterReport, fbaReport) => {
  let array = masterReport;

  array = array.map((item) => {
    const foundItem = fbaReport.filter((el) => item["ASIN"] === el["ASIN"])[0];

    if (foundItem) {
      const offer = foundItem["offers"].split("\n")[0];

      let store;
      let price;
      let rank;
      let diff;

      if (offer.match(/\d{1,4}\./)) {
        store = offer.substring(0, offer.match(/\d{1,4}\./).index).trim();
        price = parseFloat(
          offer.substring(offer.match(/\d{1,4}\./).index, offer.length).trim()
        );
        diff = Number(price) - Number(item["Breakeven"]);
      } else {
        store = "No featured offers";
        price = "N.A";
        diff = "N.A";
      }

      rank = parseInt(foundItem["Sales Rank"].split("\n")[0]);

      return {
        SKU: item["SKU"],
        Prefix: item["Prefix"],
        "Model#": item["Model#"],
        "Vendor Name": item["Vendor Name"],
        ASIN: item["ASIN"],
        UPC: item["UPC"],
        "Item Title": item["Item Title"],
        "Master Pack": item["Master Pack"],
        "Inner Pack": item["Inner Pack"],
        "Gross Price": item["Gross Price"],
        "Discount(%)": item["Discount(%)"],
        "Net Price": item["Net Price"],
        Quantity: "",
        Amount: "",
        "Re-order Recommendation": item["Re-order Recommendation"],
        "Differencof Cost and Net price":
          item["Differencof Cost and Net price"],
        "Item Brand": item["Item Brand"],
        "SKU Status": item["SKU Status"],
        "Last Modified by": item["Last Modified by"],
        "Inactivity End Date": item["Inactivity End Date"],
        Remarks: item["Remarks"],
        "MOV AMOUNT": item["MOV AMOUNT"],
        "MOV-FREE SHIPPING": item["MOV-FREE SHIPPING"],
        "MOV DISCOUNT": item["MOV DISCOUNT"],
        "Loss Selling": item["Loss Selling"],
        "Grand Total": item["Grand Total"],
        "Sales Days": item["Sales Days"],
        "Strike Rate": item["Strike Rate"],
        ">SR": item[">SR"],
        "<SR": item["<SR"],
        "Avg>SR": item["Avg>SR"],
        "Avg<SR": item["Avg<SR"],
        OS: item["OS"],
        CS: item["CS"],
        AS: item["AS"],
        "Lifetime Qty Sales": item["Lifetime Qty Sales"],
        "SALES 2019": item["SALES 2019"],
        "Current year sales": item["Current year sales"],
        "30 days": item["30 days"],
        "15 days": item["15 days"],
        "7 days": item["7 days"],
        "3 days": item["3 days"],
        "1 day": item["1 day"],
        value: item["value"],
        count: item["count"],
        "Sales Rank": item["Sales Rank"],
        "BuyBox Price/Landed Price": item["BuyBox Price/Landed Price"],
        "Prep Instruction": item["Prep Instruction"],
        "AFN-FUL-QTY": item["AFN-FUL-QTY"],
        "AFN-RSRVD-QTY": item["AFN-RSRVD-QTY"],
        "AFN-INBND-WRKN-QTY": item["AFN-INBND-WRKN-QTY"],
        "AFN-INBND-SHPD-QTY": item["AFN-INBND-SHPD-QTY"],
        "AFN-INBND-RCVN-QTY": item["AFN-INBND-RCVN-QTY"],
        "Total Inventory": item["Total Inventory"],
        "FBA ASIN Qty": item["FBA ASIN Qty"],
        "Diff between FBA ASIN Qty and Total Inventory":
          item["Diff between FBA ASIN Qty and Total Inventory"],
        "SNL ASIN Qty": item["SNL ASIN Qty"],
        "Diff between SNL ASIN Qty and Total Inventory":
          item["Diff between SNL ASIN Qty and Total Inventory"],
        "SNL SKU Qty": item["SNL SKU Qty"],
        "Diff between SNL SKU Qty and Total Inventory":
          item["Diff between SNL SKU Qty and Total Inventory"],
        OFFERS: item["OFFERS"],
        "ADDITIONAL INFO": item["ADDITIONAL INFO"],
        "Parent Child Info": item["Parent Child Info"],
        "Total Cost": item["Total Cost"],
        "Total Cost after adding extra charges":
          item["Total Cost after adding extra charges"],
        "FBA Cost": item["FBA Cost"],
        "Amz Commission %": item["Amz Commission %"],
        MAP: item["MAP"],
        "MAP as per BE": item["MAP as per BE"],
        Floor: item["Floor"],
        Ceiling: item["Ceiling"],
        "Listing Price": item["Listing Price"],
        Breakeven: item["Breakeven"],
        "NEW BE AS PER COST VARIANCE": item["NEW BE AS PER COST VARIANCE"],
        "P/L": item["P/L"],
        "P/L%": item["P/L%"],
        "Inventory Age 0 To 90 Days": item["Inventory Age 0 To 90 Days"],
        "Inventory Age 91 To 180 Days": item["Inventory Age 91 To 180 Days"],
        "Inventory Age 181 To 270 Days": item["Inventory Age 181 To 270 Days"],
        "Inventory Age 271 To 365 Days": item["Inventory Age 271 To 365 Days"],
        "Inventory Age 365 PLUS Days": item["Inventory Age 365 PLUS Days"],
        "Total ageing Inventory": item["Total ageing Inventory"],
        "Scrapper Price": item["Scrapper Price"],
        "Product Category Id": item["Product Category Id"],
        "LTSF SUM": item["LTSF SUM"],
        "LTSF QTY": item["LTSF QTY"],
        "Remarks Pricing": item["Remarks Pricing"],
        "FOLLWING MAP": item["FOLLWING MAP"],
        "Customer Reviews": foundItem["Customer Reviews"],
        "Sales Per Month": foundItem["Sales Per Month"],
        Rank: isNaN(rank) ? "No sales rank available." : rank,
        offers: store,
        Price: price,
        DIFF: diff,
      };
    } else {
      return {
        SKU: item["SKU"],
        Prefix: item["Prefix"],
        "Model#": item["Model#"],
        "Vendor Name": item["Vendor Name"],
        ASIN: item["ASIN"],
        UPC: item["UPC"],
        "Item Title": item["Item Title"],
        "Master Pack": item["Master Pack"],
        "Inner Pack": item["Inner Pack"],
        "Gross Price": item["Gross Price"],
        "Discount(%)": item["Discount(%)"],
        "Net Price": item["Net Price"],
        Quantity: "",
        Amount: "",
        "Re-order Recommendation": item["Re-order Recommendation"],
        "Differencof Cost and Net price":
          item["Differencof Cost and Net price"],
        "Item Brand": item["Item Brand"],
        "SKU Status": item["SKU Status"],
        "Last Modified by": item["Last Modified by"],
        "Inactivity End Date": item["Inactivity End Date"],
        Remarks: item["Remarks"],
        "MOV AMOUNT": item["MOV AMOUNT"],
        "MOV-FREE SHIPPING": item["MOV-FREE SHIPPING"],
        "MOV DISCOUNT": item["MOV DISCOUNT"],
        "Loss Selling": item["Loss Selling"],
        "Grand Total": item["Grand Total"],
        "Sales Days": item["Sales Days"],
        "Strike Rate": item["Strike Rate"],
        ">SR": item[">SR"],
        "<SR": item["<SR"],
        "Avg>SR": item["Avg>SR"],
        "Avg<SR": item["Avg<SR"],
        OS: item["OS"],
        CS: item["CS"],
        AS: item["AS"],
        "Lifetime Qty Sales": item["Lifetime Qty Sales"],
        "SALES 2019": item["SALES 2019"],
        "Current year sales": item["Current year sales"],
        "30 days": item["30 days"],
        "15 days": item["15 days"],
        "7 days": item["7 days"],
        "3 days": item["3 days"],
        "1 day": item["1 day"],
        value: item["value"],
        count: item["count"],
        "Sales Rank": item["Sales Rank"],
        "BuyBox Price/Landed Price": item["BuyBox Price/Landed Price"],
        "Prep Instruction": item["Prep Instruction"],
        "AFN-FUL-QTY": item["AFN-FUL-QTY"],
        "AFN-RSRVD-QTY": item["AFN-RSRVD-QTY"],
        "AFN-INBND-WRKN-QTY": item["AFN-INBND-WRKN-QTY"],
        "AFN-INBND-SHPD-QTY": item["AFN-INBND-SHPD-QTY"],
        "AFN-INBND-RCVN-QTY": item["AFN-INBND-RCVN-QTY"],
        "Total Inventory": item["Total Inventory"],
        "FBA ASIN Qty": item["FBA ASIN Qty"],
        "Diff between FBA ASIN Qty and Total Inventory":
          item["Diff between FBA ASIN Qty and Total Inventory"],
        "SNL ASIN Qty": item["SNL ASIN Qty"],
        "Diff between SNL ASIN Qty and Total Inventory":
          item["Diff between SNL ASIN Qty and Total Inventory"],
        "SNL SKU Qty": item["SNL SKU Qty"],
        "Diff between SNL SKU Qty and Total Inventory":
          item["Diff between SNL SKU Qty and Total Inventory"],
        OFFERS: item["OFFERS"],
        "ADDITIONAL INFO": item["ADDITIONAL INFO"],
        "Parent Child Info": item["Parent Child Info"],
        "Total Cost": item["Total Cost"],
        "Total Cost after adding extra charges":
          item["Total Cost after adding extra charges"],
        "FBA Cost": item["FBA Cost"],
        "Amz Commission %": item["Amz Commission %"],
        MAP: item["MAP"],
        "MAP as per BE": item["MAP as per BE"],
        Floor: item["Floor"],
        Ceiling: item["Ceiling"],
        "Listing Price": item["Listing Price"],
        Breakeven: item["Breakeven"],
        "NEW BE AS PER COST VARIANCE": item["NEW BE AS PER COST VARIANCE"],
        "P/L": item["P/L"],
        "P/L%": item["P/L%"],
        "Inventory Age 0 To 90 Days": item["Inventory Age 0 To 90 Days"],
        "Inventory Age 91 To 180 Days": item["Inventory Age 91 To 180 Days"],
        "Inventory Age 181 To 270 Days": item["Inventory Age 181 To 270 Days"],
        "Inventory Age 271 To 365 Days": item["Inventory Age 271 To 365 Days"],
        "Inventory Age 365 PLUS Days": item["Inventory Age 365 PLUS Days"],
        "Total ageing Inventory": item["Total ageing Inventory"],
        "Scrapper Price": item["Scrapper Price"],
        "Product Category Id": item["Product Category Id"],
        "LTSF SUM": item["LTSF SUM"],
        "LTSF QTY": item["LTSF QTY"],
        "Remarks Pricing": item["Remarks Pricing"],
        "FOLLWING MAP": item["FOLLWING MAP"],
        "Customer Reviews": "",
        "Sales Per Month": "",
        Rank: "",
        offers: "No featured offers",
        Price: "N.A",
        DIFF: "N.A",
      };
    }
  });

  return { array };
};

export default finalReport;
