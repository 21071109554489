import constructSalesCountData from "./constructSalesCountData";

const masterFile = (
  orderResponse,
  salesResponse1,
  salesResponse2,
  salesResponse3,
  snlResponse,
  healthResponse,
  inventoryResponse,
  vendorResponse,
  violationResponse,
  beResponse,
  fbaFeeResponse,
  fbaProductResponse,
  feePreviewResponse,
  pricingResponse,
  mrResponse
) => {
  let firstArray = fbaProductResponse;
  let firstArrayIndex = firstArray.map((el) => el["SKU"]);

  // NOTE:  MANAGE INVENTORY FILE PROCESSING
  const date1 = new Date();

  let inventory = inventoryResponse.filter(
    (el) =>
      el["condition"] === "New" ||
      el["condition"] === "new" ||
      el["condition"] === "NewOEM" ||
      el["condition"] === "New OEM" ||
      el["condition"] === "newoem" ||
      el["condition"] === "new oem"
  );

  const inventoryCopy = [...inventory];
  const firstArrayInitialCopy = [...firstArray];

  let initialInventoryIndex = inventoryCopy.map((inv) => inv["sku"]);
  firstArray = firstArray.filter((el) =>
    initialInventoryIndex.includes(el["SKU"])
  );

  inventory = inventory.filter(
    (el) =>
      Number(el["afn-fulfillable-quantity"]) +
        Number(el["afn-reserved-quantity"]) +
        Number(el["afn-inbound-working-quantity"]) +
        Number(el["afn-inbound-shipped-quantity"]) +
        Number(el["afn-inbound-receiving-quantity"]) >
      0
  );

  let inventoryIndex = inventory.map((inv) => inv["sku"]);
  firstArray = firstArray.map((item) => {
    const found = inventoryIndex.indexOf(item["SKU"]);
    const foundForPrice = initialInventoryIndex.indexOf(item["SKU"]);

    return {
      ...item,
      "Selling Price":
        foundForPrice !== -1
          ? inventoryCopy[foundForPrice]["your-price"]
          : "#N/A",
      "AFN-FUL-QTY":
        found !== -1 ? inventory[found]["afn-fulfillable-quantity"] : "0",
      "AFN-RSRVD-QTY":
        found !== -1 ? inventory[found]["afn-reserved-quantity"] : "0",
      "AFN-INBND-WRKN-QTY":
        found !== -1 ? inventory[found]["afn-inbound-working-quantity"] : "0",
      "AFN-INBND-SHPD-QTY":
        found !== -1 ? inventory[found]["afn-inbound-shipped-quantity"] : "0",
      "AFN-INBND-RCVN-QTY":
        found !== -1 ? inventory[found]["afn-inbound-receiving-quantity"] : "0",
      "Total Inventory":
        found !== -1
          ? Number(inventory[found]["afn-fulfillable-quantity"]) +
            Number(inventory[found]["afn-reserved-quantity"]) +
            Number(inventory[found]["afn-inbound-working-quantity"]) +
            Number(inventory[found]["afn-inbound-shipped-quantity"]) +
            Number(inventory[found]["afn-inbound-receiving-quantity"])
          : "0",
    };
  });

  firstArrayIndex = firstArray.map((el) => el["SKU"]);
  const firstArrayInitialIndex = firstArrayInitialCopy.map((el) => el["SKU"]);
  let inventoryArray = inventoryCopy.filter(
    (el) => !firstArrayInitialIndex.includes(el["sku"])
  );

  inventoryArray = inventoryArray.map((el) => ({
    SKU: el["sku"],
    Prefix: el["sku"].toString().slice(0, 4),
    "Model#": el["sku"].toString().slice(9),
    ASIN: el["asin"],
    "Item Title": el["product-name"],
    "Selling Price": el["your-price"],
    "AFN-FUL-QTY": el["afn-fulfillable-quantity"],
    "AFN-RSRVD-QTY": el["afn-reserved-quantity"],
    "AFN-INBND-WRKN-QTY": el["afn-inbound-working-quantity"],
    "AFN-INBND-SHPD-QTY": el["afn-inbound-shipped-quantity"],
    "AFN-INBND-RCVN-QTY": el["afn-inbound-receiving-quantity"],
    "Total Inventory":
      Number(el["afn-fulfillable-quantity"]) +
      Number(el["afn-reserved-quantity"]) +
      Number(el["afn-inbound-working-quantity"]) +
      Number(el["afn-inbound-shipped-quantity"]) +
      Number(el["afn-inbound-receiving-quantity"]),
  }));

  firstArray = [...firstArray, ...inventoryArray];

  console.log(
    `Time elapsed - Manage Inventory File: ${(
      (new Date().getTime() - date1.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  LIFETIME SALES FILE PROCESSING
  const date2 = new Date();

  if (salesResponse1 && salesResponse2 && salesResponse3) {
    const salesResponseIndex1 = salesResponse1.map((el) => el["SKU"]);
    const salesResponseIndex2 = salesResponse2.map((el) => el["SKU"]);
    const salesResponseIndex3 = salesResponse3.map((el) => el["SKU"]);

    firstArray = firstArray.map((item) => {
      const found1 = salesResponseIndex1.indexOf(item["SKU"]);
      const found2 = salesResponseIndex2.indexOf(item["SKU"]);
      const found3 = salesResponseIndex3.indexOf(item["SKU"]);

      return {
        ...item,
        "Lifetime Qty Sales":
          found1 !== -1
            ? salesResponse1[found1]["Sum of Sum of quantity"]
            : "#N/A",
        "SALES 2019":
          found2 !== -1
            ? salesResponse2[found2]["Sum of Sold qty (2019)"]
            : "#N/A",
        "Current Year Sales":
          found3 !== -1
            ? salesResponse3[found3]["Sum of Sum of quantity"]
            : "#N/A",
      };
    });
  }

  console.log(
    `Time elapsed - Lifetime Sales File: ${(
      (new Date().getTime() - date2.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  FEE PREVIEW REPORT PROCESSING
  const date3 = new Date();

  let feePreview = feePreviewResponse.filter((el) => el["currency"] === "USD");

  feePreview = feePreview.filter(
    (el) =>
      (el["estimated-pick-pack-fee-per-unit"] &&
        el["estimated-pick-pack-fee-per-unit"] !== "--") ||
      (el["expected-fulfillment-fee-per-unit"] &&
        el["expected-fulfillment-fee-per-unit"] !== "--")
  );

  console.log(
    `Time elapsed - Fee Preview Report: ${(
      (new Date().getTime() - date3.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  FBA FEE PREVIEW MASTER PROCESSING
  const date4 = new Date();

  const feePreviewIndex = feePreview.map((el) => el["sku"]);
  let fbaFee = fbaFeeResponse.map((item) => {
    const found = feePreviewIndex.indexOf(item["sku"]);

    return {
      ...item,
      "fba fees":
        found !== -1
          ? feePreview[found]["estimated-pick-pack-fee-per-unit"] &&
            feePreview[found]["estimated-pick-pack-fee-per-unit"] !== "--"
            ? feePreview[found]["estimated-pick-pack-fee-per-unit"]
            : feePreview[found]["expected-fulfillment-fee-per-unit"] &&
              feePreview[found]["expected-fulfillment-fee-per-unit"] !== "--"
            ? feePreview[found]["expected-fulfillment-fee-per-unit"]
            : "#N/A"
          : item["fba fees"],
    };
  });

  let fbaFeeIndex = fbaFee.map((el) => el["sku"]);
  let fbaFeeArray = feePreview.filter((el) => !fbaFeeIndex.includes(el["sku"]));

  fbaFeeArray = fbaFeeArray.map((el) => ({
    sku: el["sku"],
    asin: el["asin"],
    "fba fees":
      el["expected-fulfillment-fee-per-unit"] &&
      el["expected-fulfillment-fee-per-unit"] !== "--"
        ? el["expected-fulfillment-fee-per-unit"]
        : el["estimated-pick-pack-fee-per-unit"] &&
          el["estimated-pick-pack-fee-per-unit"] !== "--"
        ? el["estimated-pick-pack-fee-per-unit"]
        : "#N/A",
    DATE: new Date().toLocaleDateString(),
    Remaks: "",
    "UPDATED BY": "",
    BRAND: el["brand"],
    "product-group": el["product-group"],
    "longest-side": el["longest-side"],
    "median-side": el["median-side"],
    "shortest-side": el["shortest-side"],
    "length-and-girth": el["length-and-girth"],
    "item-package-weight": el["item-package-weight"],
  }));

  fbaFee.push(...fbaFeeArray);

  fbaFeeIndex = fbaFee.map((el) => el["sku"]);

  console.log(
    `Time elapsed - FBA Fee Preview Master: ${(
      (new Date().getTime() - date4.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  MANAGE VENDOR FILE PROCESSING
  const date5 = new Date();

  const vendorResponseIndex = vendorResponse.map((el) => el["PREFIX"]);
  firstArray = firstArray.map((item) => {
    const found = vendorResponseIndex.indexOf(item["Prefix"]);

    return {
      ...item,
      OFFERS: found !== -1 ? vendorResponse[found]["OFFERS"] : "#N/A",
      "ADDITIONAL INFO":
        found !== -1 ? vendorResponse[found]["ADDITIONAL INFO"] : "#N/A",
    };
  });

  console.log(
    `Time elapsed - Manage Vendor File: ${(
      (new Date().getTime() - date5.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  const testColumn = (item, key) => {
    return (
      !item[key] ||
      item[key] === "#N/A" ||
      item[key] === "0" ||
      item[key] === "0.00" ||
      item[key] === 0 ||
      item[key] === 0.0 ||
      item[key] === ""
    );
  };

  // NOTE:  BE MODULE FILE PROCESSING
  const date6 = new Date();

  const beResponseIndex = beResponse.map((el) => el["SKU"]);
  firstArray = firstArray.map((item) => {
    const found = beResponseIndex.indexOf(item["SKU"]);

    return {
      ...item,
      "Total Cost": found !== -1 ? beResponse[found]["Total Cost"] : "#N/A",
      "FBA Cost": found !== -1 ? beResponse[found]["FBA Cost"] : "#N/A",
      "Amz Commission %":
        found !== -1 ? beResponse[found]["Amazon Commission %"] : "#N/A",
      Floor: found !== -1 ? beResponse[found]["Floor"] : "#N/A",
      Ceiling: found !== -1 ? beResponse[found]["Ceiling"] : "#N/A",
      "MAP AS PER BE": found !== -1 ? beResponse[found]["MAP"] : "#N/A",
    };
  });

  console.log(
    `Time elapsed - BE Module File: ${(
      (new Date().getTime() - date6.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  MASTER SNL FILE PROCESSING
  const date7 = new Date();

  const snlResponseIndex = snlResponse.map((el) => el["FBA SKU"]);
  firstArray = firstArray.map((item) => {
    const found = snlResponseIndex.indexOf(item["SKU"]);

    return {
      ...item,
      "Total Cost":
        found !== -1
          ? testColumn(item, "Total Cost") && snlResponse[found]["Cost/Unit"]
            ? snlResponse[found]["Cost/Unit"]
            : item["Total Cost"]
          : item["Total Cost"],
      "FBA Cost":
        found !== -1
          ? testColumn(item, "FBA Cost") &&
            snlResponse[found]["Fulfilment Cost Sub Total"]
            ? snlResponse[found]["Fulfilment Cost Sub Total"]
            : item["FBA Cost"]
          : item["FBA Cost"],
      "Amz Commission %":
        found !== -1
          ? testColumn(item, "Amz Commission %") &&
            snlResponse[found]["Amazon Commission %"]
            ? snlResponse[found]["Amazon Commission %"]
            : item["Amz Commission %"]
          : item["Amz Commission %"],
      Floor:
        found !== -1
          ? testColumn(item, "Floor") && snlResponse[found]["Min Price"]
            ? snlResponse[found]["Min Price"]
            : item["Floor"]
          : item["Floor"],
      Ceiling:
        found !== -1
          ? testColumn(item, "Ceiling") && snlResponse[found]["Ceiling"]
            ? snlResponse[found]["Ceiling"]
            : item["Ceiling"]
          : item["Ceiling"],
      "MAP AS PER BE":
        found !== -1
          ? testColumn(item, "MAP AS PER BE") && snlResponse[found]["MAP"]
            ? snlResponse[found]["MAP"]
            : item["MAP AS PER BE"]
          : item["MAP AS PER BE"],
    };
  });

  console.log(
    `Time elapsed - Master SNL File: ${(
      (new Date().getTime() - date7.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  7 DAY PRICING FILE PROCESSING
  const date8 = new Date();

  const pricingResponseIndex = pricingResponse.map((el) => el["FBA SKU"]);
  firstArray = firstArray.map((item) => {
    const found = pricingResponseIndex.indexOf(item["SKU"]);

    return {
      ...item,
      "Total Cost":
        found !== -1
          ? testColumn(item, "Total Cost") &&
            pricingResponse[found]["Cost/Unit"]
            ? pricingResponse[found]["Cost/Unit"]
            : item["Total Cost"]
          : item["Total Cost"],
      "FBA Cost":
        found !== -1
          ? testColumn(item, "FBA Cost") &&
            pricingResponse[found]["Fulfilment Cost Sub Total"]
            ? pricingResponse[found]["Fulfilment Cost Sub Total"]
            : item["FBA Cost"]
          : item["FBA Cost"],
      "Amz Commission %":
        found !== -1
          ? testColumn(item, "Amz Commission %") &&
            pricingResponse[found]["Amazon Commission %"]
            ? pricingResponse[found]["Amazon Commission %"]
            : item["Amz Commission %"]
          : item["Amz Commission %"],
      Floor:
        found !== -1
          ? testColumn(item, "Floor") && pricingResponse[found]["Min Price"]
            ? pricingResponse[found]["Min Price"]
            : item["Floor"]
          : item["Floor"],
      Ceiling:
        found !== -1
          ? testColumn(item, "Ceiling") && pricingResponse[found]["Ceiling"]
            ? pricingResponse[found]["Ceiling"]
            : item["Ceiling"]
          : item["Ceiling"],
      "MAP AS PER BE":
        found !== -1
          ? testColumn(item, "MAP AS PER BE") && pricingResponse[found]["MAP"]
            ? pricingResponse[found]["MAP"]
            : item["MAP AS PER BE"]
          : item["MAP AS PER BE"],
    };
  });

  console.log(
    `Time elapsed - 7 Day Pricing File: ${(
      (new Date().getTime() - date8.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  PREVIOUS MASTER REPORT PROCESSING
  const date9 = new Date();

  const mrResponseIndex = mrResponse.map((el) => el["SKU"]);
  firstArray = firstArray.map((item) => {
    const found = mrResponseIndex.indexOf(item["SKU"]);

    return {
      ...item,
      "Total Cost":
        found !== -1
          ? testColumn(item, "Total Cost") && mrResponse[found]["Total Cost"]
            ? mrResponse[found]["Total Cost"]
            : item["Total Cost"]
          : item["Total Cost"],
      "FBA Cost":
        found !== -1
          ? testColumn(item, "FBA Cost") && mrResponse[found]["FBA Cost"]
            ? mrResponse[found]["FBA Cost"]
            : item["FBA Cost"]
          : item["FBA Cost"],
      "Amz Commission %":
        found !== -1
          ? testColumn(item, "Amz Commission %") &&
            mrResponse[found]["Amz Commission %"]
            ? mrResponse[found]["Amz Commission %"]
            : item["Amz Commission %"]
          : item["Amz Commission %"],
      Floor:
        found !== -1
          ? mrResponse[found]["Floor"] && mrResponse[found]["Floor"] !== "#N/A"
            ? mrResponse[found]["Floor"]
            : item["Floor"]
          : item["Floor"],
      Ceiling:
        found !== -1
          ? mrResponse[found]["Ceiling"] &&
            mrResponse[found]["Ceiling"] !== "#N/A"
            ? mrResponse[found]["Ceiling"]
            : item["Ceiling"]
          : item["Ceiling"],
      MAP: found !== -1 ? mrResponse[found]["MAP"] : "#N/A",
    };
  });

  console.log(
    `Time elapsed - Previous Master Report: ${(
      (new Date().getTime() - date9.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  INVENTORY HEALTH FILE PROCESSING
  const date10 = new Date();

  if (healthResponse) {
    let healthResponseCopy = [...healthResponse];
    healthResponseCopy = healthResponseCopy.filter(
      (el) => el.condition === "New" || el.condition === "No Listing"
    );
    healthResponseCopy = healthResponseCopy.filter(
      (el) => el.currency === "USD"
    );

    const healthResponseIndex = healthResponseCopy.map((el) => el["sku"]);

    firstArray = firstArray.map((item) => {
      const found = healthResponseIndex.indexOf(item["SKU"]);

      return {
        ...item,
        "LTSF QTY":
          found !== -1
            ? Number(
                healthResponseCopy[found]["qty-to-be-charged-ltsf-12-mo"]
              ) +
              Number(healthResponseCopy[found]["qty-to-be-charged-ltsf-6-mo"])
            : "#N/A",
        "LTSF SUM":
          found !== -1
            ? Number(healthResponseCopy[found]["projected-ltsf-12-mo"]) +
              Number(healthResponseCopy[found]["projected-ltsf-6-mo"])
            : "#N/A",
        "Inventory Age 0 To 90 Days":
          found !== -1
            ? healthResponseCopy[found]["inv-age-0-to-90-days"]
            : "#N/A",
        "Inventory Age 91 To 180 Days":
          found !== -1
            ? healthResponseCopy[found]["inv-age-91-to-180-days"]
            : "#N/A",
        "Inventory Age 181 To 270 Days":
          found !== -1
            ? healthResponseCopy[found]["inv-age-181-to-270-days"]
            : "#N/A",
        "Inventory Age 271 To 365 Days":
          found !== -1
            ? healthResponseCopy[found]["inv-age-271-to-365-days"]
            : "#N/A",
        "Inventory Age 365 PLUS Days":
          found !== -1
            ? healthResponseCopy[found]["inv-age-365-plus-days"]
            : "#N/A",
      };
    });
  }

  console.log(
    `Time elapsed - Inventory Health File: ${(
      (new Date().getTime() - date10.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  MAP VIOLATION VENDOR FILE PROCESSING
  const date11 = new Date();

  if (violationResponse) {
    firstArray = firstArray.map((item) => {
      const found = violationResponse.findIndex(
        (el) => item["Prefix"] === el["Prefix"]
      );

      return {
        ...item,
        "FOLLWING MAP":
          found !== -1 ? violationResponse[found]["remarks"] : "#N/A",
      };
    });
  }

  console.log(
    `Time elapsed - Map Violation Vendor File: ${(
      (new Date().getTime() - date11.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  firstArray = firstArray.map((item) => {
    const found = mrResponseIndex.indexOf(item["SKU"]);

    return {
      ...item,
      "Remarks Pricing":
        found !== -1
          ? mrResponse[found]["Remarks Pricing"]
            ? mrResponse[found]["Remarks Pricing"]
            : "#N/A"
          : "#N/A",
    };
  });

  firstArray = firstArray.map((item) => {
    return {
      ...item,
      MAP:
        !(
          item["MAP AS PER BE"] === "$0.00" ||
          item["MAP AS PER BE"] === "$0" ||
          item["MAP AS PER BE"] === "0" ||
          item["MAP AS PER BE"] === 0 ||
          item["MAP AS PER BE"] === "" ||
          item["MAP AS PER BE"] === "#N/A" ||
          !item["MAP AS PER BE"]
        ) && item["MAP"] !== item["MAP AS PER BE"]
          ? item["MAP AS PER BE"]
          : item["MAP"],
    };
  });

  // NOTE:  ALL ORDER FILE PROCESSING
  const date12 = new Date();

  if (orderResponse) {
    const salesCountData = constructSalesCountData(orderResponse);

    const salesCountIndex = salesCountData.map((el) => el["sku"]);
    firstArray = firstArray.map((item) => {
      const found = salesCountIndex.indexOf(item["SKU"]);

      return {
        ...item,
        "Qty Sales(30)":
          found !== -1 ? salesCountData[found]["30 days"] : "#N/A",
        "Qty Sales(15)":
          found !== -1 ? salesCountData[found]["15 days"] : "#N/A",
        "Qty Sales(7)": found !== -1 ? salesCountData[found]["7 days"] : "#N/A",
        "Qty Sales(3)": found !== -1 ? salesCountData[found]["3 days"] : "#N/A",
        "Qty Sales(1)": found !== -1 ? salesCountData[found]["1 day"] : "#N/A",
      };
    });
  }

  console.log(
    `Time elapsed - All Order File: ${(
      (new Date().getTime() - date12.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  firstArray = firstArray.map((item) => {
    const found = fbaFeeIndex.indexOf(item["SKU"]);

    return {
      ...item,
      "FBA Cost":
        found !== -1
          ? fbaFee[found]["fba fees"] && fbaFee[found]["fba fees"] !== "#N/A"
            ? fbaFee[found]["fba fees"]
            : item["FBA Cost"]
          : item["FBA Cost"],
    };
  });

  const firstArrayCopy = firstArray.map((item) => ({
    SKU: item["SKU"],
    Prefix: item["Prefix"],
    "Model#": item["Model#"],
    "Vendor Name": item["Vendor Name"],
    ASIN: item["ASIN"],
    UPC: item["UPC"],
    "Item Title": item["Item Title"],
    "Master Pack": item["Master Pack"],
    "Inner Pack": item["Inner Pack"],
    "Gross Price": item["Gross Price"],
    "Discount(%)": item["Discount(%)"],
    "Net Price": item["Net Price"],
    "Item Brand": item["Item Brand"],
    "SKU Status": item["SKU Status"],
    "Last Modified by": item["Last Modified by"],
    "Inactivity End Date": item["Inactivity End Date"],
    Remarks: item["Remarks"],
    "MOV AMOUNT": item["MOV AMOUNT"],
    "MOV-FREE SHIPPING": item["MOV-FREE SHIPPING"],
    "MOV DISCOUNT": item["MOV DISCOUNT"],
    "Loss Selling": item["Loss Selling"],
    "Lifetime Qty Sales": item["Lifetime Qty Sales"].toString().startsWith("$")
      ? item["Lifetime Qty Sales"].toString().slice(1)
      : item["Lifetime Qty Sales"],
    "SALES 2019": item["SALES 2019"].toString().startsWith("$")
      ? item["SALES 2019"].toString().slice(1)
      : item["SALES 2019"],
    "Current Year Sales": item["Current Year Sales"].toString().startsWith("$")
      ? item["Current Year Sales"].toString().slice(1)
      : item["Current Year Sales"],
    "Qty Sales(365)": item["Qty Sales(365)"],
    "Qty Sales(180)": item["Qty Sales(180)"],
    "Qty Sales(90)": item["Qty Sales(90)"],
    "Qty Sales(60)": item["Qty Sales(60)"],
    "30 days": isNaN(Number(item["Qty Sales(30)"]))
      ? item["Qty Sales(30)"]
      : Number(item["Qty Sales(30)"]),
    "15 days": isNaN(Number(item["Qty Sales(15)"]))
      ? item["Qty Sales(15)"]
      : Number(item["Qty Sales(15)"]),
    "7 days": isNaN(Number(item["Qty Sales(7)"]))
      ? item["Qty Sales(7)"]
      : Number(item["Qty Sales(7)"]),
    "3 days": isNaN(Number(item["Qty Sales(3)"]))
      ? item["Qty Sales(3)"]
      : Number(item["Qty Sales(3)"]),
    "1 day": isNaN(Number(item["Qty Sales(1)"]))
      ? item["Qty Sales(1)"]
      : Number(item["Qty Sales(1)"]),
    "Sales Rank": item["Sales Rank"],
    "Product Category Id": item["Product Category Id"],
    "BuyBox Price/Landed Price": item["BuyBox Price/Landed Price"],
    "Prep Instruction": item["Prep Instruction"],
    "AFN-FUL-QTY": isNaN(Number(item["AFN-FUL-QTY"]))
      ? item["AFN-FUL-QTY"]
      : Number(item["AFN-FUL-QTY"]),
    "AFN-RSRVD-QTY": isNaN(Number(item["AFN-RSRVD-QTY"]))
      ? item["AFN-RSRVD-QTY"]
      : Number(item["AFN-RSRVD-QTY"]),
    "AFN-INBND-WRKN-QTY": isNaN(Number(item["AFN-INBND-WRKN-QTY"]))
      ? item["AFN-INBND-WRKN-QTY"]
      : Number(item["AFN-INBND-WRKN-QTY"]),
    "AFN-INBND-SHPD-QTY": isNaN(Number(item["AFN-INBND-SHPD-QTY"]))
      ? item["AFN-INBND-SHPD-QTY"]
      : Number(item["AFN-INBND-SHPD-QTY"]),
    "AFN-INBND-RCVN-QTY": isNaN(Number(item["AFN-INBND-RCVN-QTY"]))
      ? item["AFN-INBND-RCVN-QTY"]
      : Number(item["AFN-INBND-RCVN-QTY"]),
    "Total Inventory": isNaN(Number(item["Total Inventory"]))
      ? item["Total Inventory"]
      : Number(item["Total Inventory"]),
    OFFERS: item["OFFERS"],
    "ADDITIONAL INFO": item["ADDITIONAL INFO"],
    "Parent Child Info": item["Parent Child Info"]
      ? item["Parent Child Info"]
      : "#N/A",
    "Total Cost": item["Total Cost"].toString().startsWith("$")
      ? item["Total Cost"].toString().slice(1)
      : item["Total Cost"],
    "FBA Cost": item["FBA Cost"].toString().startsWith("$")
      ? isNaN(Number(item["FBA Cost"].toString().slice(1)))
        ? item["FBA Cost"].toString().slice(1)
        : Number(item["FBA Cost"].toString().slice(1))
      : isNaN(Number(item["FBA Cost"]))
      ? item["FBA Cost"]
      : Number(item["FBA Cost"]),
    "Amz Commission %": item["Amz Commission %"].toString().startsWith("$")
      ? isNaN(Number(item["Amz Commission %"].toString().slice(1)))
        ? item["Amz Commission %"].toString().slice(1)
        : Number(item["Amz Commission %"].toString().slice(1))
      : isNaN(Number(item["Amz Commission %"]))
      ? item["Amz Commission %"]
      : Number(item["Amz Commission %"]),
    MAP: item["MAP"].toString().startsWith("$")
      ? isNaN(Number(item["MAP"].toString().slice(1)))
        ? item["MAP"].toString().slice(1)
        : Number(item["MAP"].toString().slice(1))
      : isNaN(Number(item["MAP"]))
      ? item["MAP"]
      : Number(item["MAP"]),
    "MAP AS PER BE": item["MAP AS PER BE"].toString().startsWith("$")
      ? isNaN(Number(item["MAP AS PER BE"].toString().slice(1)))
        ? item["MAP AS PER BE"].toString().slice(1)
        : Number(item["MAP AS PER BE"].toString().slice(1))
      : isNaN(Number(item["MAP AS PER BE"]))
      ? item["MAP AS PER BE"]
      : Number(item["MAP AS PER BE"]),
    Floor: item["Floor"].toString().startsWith("$")
      ? isNaN(Number(item["Floor"].toString().slice(1)))
        ? item["Floor"].toString().slice(1)
        : Number(item["Floor"].toString().slice(1))
      : isNaN(Number(item["Floor"]))
      ? item["Floor"]
      : Number(item["Floor"]),
    Ceiling: item["Ceiling"].toString().startsWith("$")
      ? isNaN(Number(item["Ceiling"].toString().slice(1)))
        ? item["Ceiling"].toString().slice(1)
        : Number(item["Ceiling"].toString().slice(1))
      : isNaN(Number(item["Ceiling"]))
      ? item["Ceiling"]
      : Number(item["Ceiling"]),
    "Listing Price": item["Selling Price"].toString().startsWith("$")
      ? item["Selling Price"].toString().slice(1)
      : item["Selling Price"],
    Breakeven: item["Breakeven"],
    "Inventory Age 0 To 90 Days": isNaN(
      Number(item["Inventory Age 0 To 90 Days"])
    )
      ? item["Inventory Age 0 To 90 Days"]
      : Number(item["Inventory Age 0 To 90 Days"]),
    "Inventory Age 91 To 180 Days": isNaN(
      Number(item["Inventory Age 91 To 180 Days"])
    )
      ? item["Inventory Age 91 To 180 Days"]
      : Number(item["Inventory Age 91 To 180 Days"]),
    "Inventory Age 181 To 270 Days": isNaN(
      Number(item["Inventory Age 181 To 270 Days"])
    )
      ? item["Inventory Age 181 To 270 Days"]
      : Number(item["Inventory Age 181 To 270 Days"]),
    "Inventory Age 271 To 365 Days": isNaN(
      Number(item["Inventory Age 271 To 365 Days"])
    )
      ? item["Inventory Age 271 To 365 Days"]
      : Number(item["Inventory Age 271 To 365 Days"]),
    "Inventory Age 365 PLUS Days": isNaN(
      Number(item["Inventory Age 365 PLUS Days"])
    )
      ? item["Inventory Age 365 PLUS Days"]
      : Number(item["Inventory Age 365 PLUS Days"]),
    "FOLLWING MAP": item["FOLLWING MAP"],
    "Remarks Pricing": item["Remarks Pricing"],
    "LTSF SUM": isNaN(Number(item["LTSF SUM"]))
      ? item["LTSF SUM"]
      : Number(item["LTSF SUM"]),
    "LTSF QTY": isNaN(Number(item["LTSF QTY"]))
      ? item["LTSF QTY"]
      : Number(item["LTSF QTY"]),
  }));

  const secondArray = [...fbaFee];

  const secondArrayCopy = secondArray.map((item) => ({
    sku: item["sku"],
    asin: item["asin"],
    "fba fees": isNaN(Number(item["fba fees"]))
      ? item["fba fees"]
      : Number(item["fba fees"]),
    BRAND: !item["BRAND"] || item["BRAND"] === "" ? "#N/A" : item["BRAND"],
    "product-group":
      !item["product-group"] || item["product-group"] === ""
        ? "#N/A"
        : isNaN(Number(item["product-group"]))
        ? item["product-group"]
        : Number(item["product-group"]),
    "longest-side":
      !item["longest-side"] || item["longest-side"] === ""
        ? "#N/A"
        : isNaN(Number(item["longest-side"]))
        ? item["longest-side"]
        : Number(item["longest-side"]),
    "median-side":
      !item["median-side"] || item["median-side"] === ""
        ? "#N/A"
        : isNaN(Number(item["median-side"]))
        ? item["median-side"]
        : Number(item["median-side"]),
    "shortest-side":
      !item["shortest-side"] || item["shortest-side"] === ""
        ? "#N/A"
        : isNaN(Number(item["shortest-side"]))
        ? item["shortest-side"]
        : Number(item["shortest-side"]),
    "length-and-girth":
      !item["length-and-girth"] || item["length-and-girth"] === ""
        ? "#N/A"
        : isNaN(Number(item["length-and-girth"]))
        ? item["length-and-girth"]
        : Number(item["length-and-girth"]),
    "item-package-weight":
      !item["item-package-weight"] || item["item-package-weight"] === ""
        ? "#N/A"
        : isNaN(Number(item["item-package-weight"]))
        ? item["item-package-weight"]
        : Number(item["item-package-weight"]),
    DATE: item["DATE"] ? new Date(item["DATE"]) : "",
    "UPDATED BY": item["UPDATED BY"],
    Remaks: item["Remaks"],
  }));

  return {
    firstArray: firstArrayCopy,
    secondArray: secondArrayCopy,
  };
};

export default masterFile;
