import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

// import { Formik, Form } from "formik";
// import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    image: {
        backgroundImage: "url(https://source.unsplash.com/user/erondu)",
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

// const loginSchema = Yup.object().shape({
//     email: Yup.string(),
//     password: Yup.string(),
// });

const SignInSide = (props) => {
    const classes = useStyles();
    console.log("login props", props);

    return (
        <Grid container component='main' className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid
                style={{ paddingTop: "50px" }}
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={6}
                square>
                <Grid container justify='center'>
                    <img
                        alt='logo'
                        src={require("../assets/ergode.png")}
                        style={{
                            margin: "0 auto",
                            width: "80%",
                            maxWidth: "230px",
                        }}
                    />
                </Grid>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component='h1' variant='h5'>
                        Sign in
                    </Typography>
                    {/* <Formik
                        initialValues={{
                            email: "",
                            password: "",
                        }}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            localStorage.setItem("loggedIn", true);
                            props.history.push(`/`);
                            setSubmitting(false);
                            resetForm();
                        }}
                        validationSchema={loginSchema}>
                        {({ isSubmitting }) => (
                            <Form className={classes.form} noValidate> */}
                    <Grid container>
                        <Grid container justify='center'>
                            <Button
                                // disabled={isSubmitting}
                                onClick={() => {
                                    localStorage.setItem("loggedIn", true);
                                    window.location = `/dashboard/reports`;
                                }}
                                type='submit'
                                variant='contained'
                                color='primary'
                                className={classes.submit}>
                                Press to Enter
                            </Button>
                        </Grid>
                    </Grid>
                    {/* </Form>
                        )}
                    </Formik> */}
                </div>
            </Grid>
        </Grid>
    );
};

export default SignInSide;
