import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";
import red from "@material-ui/core/colors/red";

import "./App.css";

import PrivateRoute from "./components/privateRoute.component";
import DashBoard from "./layouts/dashBoard.layout";
import Login from "./layouts/login.layout";

const hist = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: red,
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router history={hist}>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute
            loggedIn={localStorage.getItem("loggedIn")}
            path="/dashboard"
            component={DashBoard}
          />
          <Redirect from="/" to="/dashboard/master" />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
