import React from "react";
import ReactDropZone from "react-dropzone";
import { Grid, Typography } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import sheetCleanUp from "../utils/sheetCleanUp";

const DropZone = ({ name, getResponse, uploading, setUpload }) => {
  return (
    <ReactDropZone
      onDrop={(acceptedFiles) => {
        sheetCleanUp(acceptedFiles, name, getResponse, setUpload);
      }}
      accept={
        name === "Product Master Table*"
          ? [".xlsx", ".xls", ".ods", ".csv"]
          : [".xlsx", ".xls", ".ods"]
      }
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div
            {...getRootProps()}
            style={{
              textAlign: "center",
              cursor: "pointer",
              padding: "20px 10px",
              border: "2px solid #3f51b5",
              borderRadius: "5px",
            }}
          >
            <input {...getInputProps()} />
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
              spacing={2}
            >
              <Typography variant="h6" color="primary">
                {name.toUpperCase()}
              </Typography>
              {uploading ? (
                <>
                  <FileCopyIcon
                    fontSize="large"
                    color="primary"
                    style={{ margin: "5px" }}
                  ></FileCopyIcon>
                  <Typography variant="h6" color="primary">
                    Uploading...
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h6">Drag and drop your file.</Typography>
                  <FileCopyIcon
                    fontSize="large"
                    color="primary"
                    style={{ margin: "5px" }}
                  ></FileCopyIcon>
                  <Typography variant="h6">Or click here to browse.</Typography>
                </>
              )}
            </Grid>
          </div>
        </section>
      )}
    </ReactDropZone>
  );
};

export default DropZone;
