const constructSalesData = (incomingData) => {
    let data = [];

    const skuArray = incomingData.map((el) => el["sku"]);
    const dateArray = incomingData.map((el) => el["purchase-date"]);

    const labels = [...new Set(skuArray)];
    const days = [...new Set(dateArray)];

    data[0] = ["Row Labels", ...days, "Grand Total"];

    labels.forEach((label, index) => {
        data[index + 1] = [];
    });

    labels.forEach((label, index) => {
        data[index + 1][0] = label;
    });

    incomingData.forEach((item, index) => {
        const rowLabelIndex = data.findIndex((a) => a[0] === item["sku"]);
        const dayIndex = data[0].indexOf(item["purchase-date"]);

        data[rowLabelIndex][0] = item["sku"];
        data[rowLabelIndex][dayIndex] = data[rowLabelIndex][dayIndex]
            ? Number(data[rowLabelIndex][dayIndex]) + Number(item["quantity"])
            : Number(item["quantity"]);
    });

    const index1 = data[0].indexOf("Row Labels");
    const index2 = data[0].indexOf("Grand Total");

    const keys = data[0];

    const strikeRateData = data.map((el, i) => {
        let obj = {};

        for (let j = index1; j <= index2; j++) {
            if (keys[j] === "Row Labels" || keys[j] === "Grand Total") {
                obj[keys[j]] = data[i][j];
            } else {
                obj[`Day ${j}`] = data[i][j] ? data[i][j] : 0;
            }
        }

        return obj;
    });

    strikeRateData.splice(0, 1);

    const calculatedData = strikeRateData.map((el) => {
        const salesDays = Object.entries(el).reduce(
            (acc, item) =>
                Number(item[1]) !== 0 && item[0] !== "Grand Total" && item[0] !== "Row Labels"
                    ? ++acc
                    : acc,
            0
        );

        const grandTotal = Object.entries(el).reduce(
            (acc, item) =>
                Number(item[1]) !== 0 && item[0] !== "Grand Total" && item[0] !== "Row Labels"
                    ? acc + Number(item[1])
                    : acc,
            0
        );

        const strikeRate = (grandTotal / salesDays).toFixed(2);

        const greaterThanStrikeRate = Object.entries(el).reduce(
            (acc, item) =>
                Number(item[1]) !== 0 &&
                Number(item[1]) >= strikeRate &&
                item[0] !== "Grand Total" &&
                item[0] !== "Row Labels"
                    ? { number: ++acc.number, sum: acc.sum + Number(item[1]) }
                    : { number: acc.number, sum: acc.sum },
            { number: 0, sum: 0 }
        );

        const lowerThanStrikeRate = Object.entries(el).reduce(
            (acc, item) =>
                Number(item[1]) !== 0 &&
                Number(item[1]) < strikeRate &&
                item[0] !== "Grand Total" &&
                item[0] !== "Row Labels"
                    ? { number: ++acc.number, sum: acc.sum + Number(item[1]) }
                    : { number: acc.number, sum: acc.sum },
            { number: 0, sum: 0 }
        );

        const greaterThanStrikeRateAverage =
            greaterThanStrikeRate.number === 0
                ? 0
                : greaterThanStrikeRate.sum / greaterThanStrikeRate.number;

        const lowerThanStrikeRateAverage =
            lowerThanStrikeRate.number === 0
                ? 0
                : lowerThanStrikeRate.sum / lowerThanStrikeRate.number;

        return {
            "Row Labels": el["Row Labels"],
            "Grand Total": grandTotal,
            "Sales Days": salesDays,
            "Strike Rate": strikeRate,
            ">SR": greaterThanStrikeRate.number,
            "<SR": lowerThanStrikeRate.number,
            "Avg>SR": greaterThanStrikeRateAverage,
            "Avg<SR": lowerThanStrikeRateAverage,
            OS: greaterThanStrikeRateAverage * 15,
            CS: lowerThanStrikeRateAverage * 15,
            AS: (greaterThanStrikeRateAverage * 15 + lowerThanStrikeRateAverage * 15) / 2,
        };
    });

    return calculatedData;
};

export default constructSalesData;
