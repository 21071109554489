import XLSX from "xlsx";

const createOutputSheet = (data, sheetName, dateFormatting) => {
  const fileName = `${sheetName}.xlsx`;
  let opts;
  if (dateFormatting) {
    opts = {
      cellDates: true,
      dateNF: "MM/dd/yyyy",
      bookSST: true,
      compression: true,
      bookType: "xlsx",
    };
  } else {
    opts = {
      cellDates: true,
      bookSST: true,
      compression: true,
      bookType: "xlsx",
    };
  }

  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);

  XLSX.writeFile(wb, fileName, opts);
};

export default createOutputSheet;
