import React from "react";
import { Paper, Grid, Typography, Button } from "@material-ui/core";

import DropZone from "../components/dropZone.component";
import UploadFeedBack from "../components/uploadFeedBack.component";

import uploadMasterData from "../utils/uploadMasterData";

const MultiToolUpload = () => {
  const [masterData, setMasterData] = React.useState(null);
  const [masterDataUpload, setMasterDataUpload] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);

  const handleMasterData = (val) => {
    setMasterData(val);
  };

  React.useEffect(() => {
    console.log("MASTER DATA:", masterData);
  }, [masterData]);

  return (
    <Paper
      style={{
        width: "100%",
        minHeight: "100vh",
        padding: "10px 15px 50px 15px",
      }}
    >
      <Typography component="h1" variant="h4" gutterBottom>
        Multi-Tool Convert Data
      </Typography>
      <Grid container spacing={3} justify="center">
        <Grid item xs={4}>
          <DropZone
            name="Master Sheet"
            getResponse={handleMasterData}
            uploading={masterDataUpload}
            setUpload={(val) => setMasterDataUpload(val)}
          />
          <UploadFeedBack responseObject={masterData} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        justify="center"
        style={{ marginTop: "10px" }}
      >
        {processing ? (
          <Typography color="primary" variant="h5">
            Processing...
          </Typography>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={
              processing ||
              !masterData ||
              masterData?.missingColumns?.length > 0
            }
            onClick={async () => {
              setProcessing(true);

              const res = await uploadMasterData(masterData.sheetToJson);

              var dataStr =
                "data:text/json;charset=utf-8," +
                encodeURIComponent(JSON.stringify(res));
              var dlAnchorElem = document.getElementById("downloadAnchorElem");
              dlAnchorElem.setAttribute("href", dataStr);
              dlAnchorElem.setAttribute("download", "masterData.json");
              dlAnchorElem.click();

              setProcessing(false);
            }}
          >
            Convert Master Sheet
          </Button>
        )}
      </Grid>
      <a id="downloadAnchorElem" style={{ display: "none" }}></a>
    </Paper>
  );
};

export default MultiToolUpload;
