import React from "react";
import { Paper, Grid, Typography } from "@material-ui/core";

import DropZone from "../components/dropZone.component";
import UploadFeedBack from "../components/uploadFeedBack.component";
import CustomForm from "../components/form.component";
import createOutputSheet from "../utils/createOutputSheet";

const MasterReportPage = () => {
    const [masterResponse, setMasterResponse] = React.useState(null);
    const [reorderResponse, setReorderResponse] = React.useState(null);
    const [salesResponse, setSalesResponse] = React.useState(null);
    const [mfiResponse, setMfiResponse] = React.useState(null);
    const [snlResponse, setSnlResponse] = React.useState(null);
    const [finalReport, setFinalReport] = React.useState(null);
    const [masterUpload, setMasterUpload] = React.useState(false);
    const [reorderUpload, setReorderUpload] = React.useState(false);
    const [salesUpload, setSalesUpload] = React.useState(false);
    const [mfiUpload, setMfiUpload] = React.useState(false);
    const [snlUpload, setSnlUpload] = React.useState(false);

    const handleMaster = (val) => {
        setMasterResponse(val);
    };

    const handleReorder = (val) => {
        setReorderResponse(val);
    };

    const handleSales = (val) => {
        setSalesResponse(val);
    };

    const handleMfi = (val) => {
        setMfiResponse(val);
    };

    const handleSnl = (val) => {
        setSnlResponse(val);
    };

    const handleFinalReport = (val) => {
        setFinalReport(val);
        createOutputSheet(val.firstArray, "master_report");
    };

    const handleSalesReport = (val) => {
        createOutputSheet(val, "sales_report");
    };

    React.useEffect(() => {
        console.log("MASTER:", masterResponse);
        console.log("REORDER:", reorderResponse);
        console.log("SALES:", salesResponse);
        console.log("MFI:", mfiResponse);
        console.log("SNL:", snlResponse);
        console.log("FINAL:", finalReport);
    }, [masterResponse, reorderResponse, salesResponse, finalReport, mfiResponse, snlResponse]);

    return (
        <Paper style={{ width: "100%", minHeight: "100vh", padding: "10px 15px 50px 15px" }}>
            <Typography component='h1' variant='h4' gutterBottom>
                Master Report Generation
            </Typography>
            <Grid container spacing={2} justify='center'>
                <Grid item xs={4}>
                    <DropZone
                        name='Master File'
                        getResponse={handleMaster}
                        uploading={masterUpload}
                        setUpload={(val) => setMasterUpload(val)}
                    />
                    <UploadFeedBack responseObject={masterResponse} />
                </Grid>
                <Grid item xs={4}>
                    <DropZone
                        name='Reorder File'
                        getResponse={handleReorder}
                        uploading={reorderUpload}
                        setUpload={(val) => setReorderUpload(val)}
                    />
                    <UploadFeedBack responseObject={reorderResponse} />
                </Grid>
                <Grid item xs={4}>
                    <DropZone
                        name='Sales File'
                        getResponse={handleSales}
                        uploading={salesUpload}
                        setUpload={(val) => setSalesUpload(val)}
                    />
                    <UploadFeedBack responseObject={salesResponse} />
                </Grid>
                <Grid item xs={4}>
                    <DropZone
                        name='MFI File'
                        getResponse={handleMfi}
                        uploading={mfiUpload}
                        setUpload={(val) => setMfiUpload(val)}
                    />
                    <UploadFeedBack responseObject={mfiResponse} />
                </Grid>
                <Grid item xs={4}>
                    <DropZone
                        name='SNL File'
                        getResponse={handleSnl}
                        uploading={snlUpload}
                        setUpload={(val) => setSnlUpload(val)}
                    />
                    <UploadFeedBack responseObject={snlResponse} />
                </Grid>
                <Grid item xs={8}>
                    <CustomForm
                        handleFinalReport={handleFinalReport}
                        handleSalesReport={handleSalesReport}
                        masterResponse={masterResponse}
                        reorderResponse={reorderResponse}
                        salesResponse={salesResponse}
                        mfiResponse={mfiResponse}
                        snlResponse={snlResponse}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default MasterReportPage;
