import React from "react";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute({ loggedIn, component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                loggedIn === "true" ? <Component {...props} /> : <Redirect to='/login' />
            }
        />
    );
}

export default PrivateRoute;
