const reorderFile = (
  scrapperResponse,
  masterResponse,
  fbaInvResponse,
  snlInvResponse,
  archiveInvResponse,
  pmtResponse,
  restrictedResponse,
  lifeSalesResponse1,
  lifeSalesResponse2,
  lifeSalesResponse3
) => {
  let firstArray = scrapperResponse;

  // NOTE:  PMT EXPORT FILE PROCESSING
  const date1 = new Date();

  let pmtIndex = pmtResponse.map((el) => el["ASIN"]);
  firstArray = firstArray.map((item) => {
    const found = pmtIndex.indexOf(item["ASIN"]);

    return {
      ...item,
      "FBA SKU":
        found !== -1
          ? `${pmtResponse[found]["Prefix"]}-FBA-${item["ItemCode"]}`
          : "#N/A",
      "SNL SKU":
        found !== -1
          ? `${pmtResponse[found]["Prefix"]}-SNL-${item["ItemCode"]}`
          : "#N/A",
    };
  });

  let secondArray = firstArray.filter(
    (el) => el["Item eligible for SNL(Yes/No)"] === "Yes"
  );

  firstArray = firstArray.filter(
    (el) => el["Item eligible for SNL(Yes/No)"] !== "Yes"
  );

  let pmtIndex2 = pmtResponse.map((el) => el["SKU"]);
  firstArray = firstArray.map((item) => {
    const found = pmtIndex2.indexOf(item["FBA SKU"]);

    return {
      ...item,
      "SKU Status": found !== -1 ? pmtResponse[found]["SKU Status"] : "#N/A",
      "Blacklisted Remarks":
        found !== -1 ? pmtResponse[found]["Remarks"] : "#N/A",
    };
  });

  secondArray = secondArray.map((item) => {
    const found = pmtIndex2.indexOf(item["SNL SKU"]);

    return {
      ...item,
      "SKU Status": found !== -1 ? pmtResponse[found]["SKU Status"] : "#N/A",
      "Blacklisted Remarks":
        found !== -1 ? pmtResponse[found]["Remarks"] : "#N/A",
    };
  });

  console.log(
    `Time elapsed - PMT Export File: ${(
      (new Date().getTime() - date1.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  RESTRICTED BRAND FILE PROCESSING
  const date6 = new Date();

  const restrictedIndex = restrictedResponse.map(
    (el) => el["Restricted Brands"]
  );
  firstArray = firstArray.map((item) => {
    const found = restrictedIndex.indexOf(item["Brand"]);

    return {
      ...item,
      "Restricted Brands": item["Brand"] ? (found !== -1 ? "Yes" : "No") : "",
    };
  });

  secondArray = secondArray.map((item) => {
    const found = restrictedIndex.indexOf(item["Brand"]);

    return {
      ...item,
      "Restricted Brands": item["Brand"] ? (found !== -1 ? "Yes" : "No") : "",
    };
  });

  console.log(
    `Time elapsed - Restricted Brand File: ${(
      (new Date().getTime() - date6.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  ARCHIVE INV FILE PROCESSING
  const date4 = new Date();

  let archiveIndex = archiveInvResponse.map((el) => el["asin-atz"]);
  let archiveIndex2 = archiveInvResponse.map((el) => el["asin-zta"]);
  firstArray = firstArray.map((item) => {
    const found = archiveIndex.indexOf(item["ASIN"]);
    const found2 = archiveIndex2.indexOf(item["ASIN"]);

    return {
      ...item,
      "Listed Y/N - ATZ": item["ASIN"]
        ? found !== -1
          ? archiveInvResponse[found]["sku-atz"]
          : "NEW"
        : "",
      "Listed Y/N - ZTA": item["ASIN"]
        ? found2 !== -1
          ? archiveInvResponse[found]["sku-zta"]
          : "NEW"
        : "",
    };
  });

  secondArray = secondArray.map((item) => {
    const found = archiveIndex.indexOf(item["ASIN"]);
    const found2 = archiveIndex2.indexOf(item["ASIN"]);

    return {
      ...item,
      "Listed Y/N - ATZ": item["ASIN"]
        ? found !== -1
          ? archiveInvResponse[found]["sku-atz"]
          : "NEW"
        : "",
      "Listed Y/N - ZTA": item["ASIN"]
        ? found2 !== -1
          ? archiveInvResponse[found]["sku-zta"]
          : "NEW"
        : "",
    };
  });

  console.log(
    `Time elapsed - Archive Inv File: ${(
      (new Date().getTime() - date4.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  MASTER FILE PROCESSING
  const date5 = new Date();

  let masterAsinIndex = masterResponse.map((el) => el["ASIN"]);
  let masterSkuIndex = masterResponse.map((el) => el["SKU"]);
  firstArray = firstArray.map((item) => {
    const foundAsin = masterAsinIndex.indexOf(item["ASIN"]);
    const foundSkuFba = masterSkuIndex.indexOf(item["FBA SKU"]);

    return {
      ...item,
      "Aged Inventory": item["ASIN"]
        ? foundAsin !== -1
          ? masterResponse[foundAsin]["Total ageing Inventory"]
          : "#N/A"
        : "",
      "30 Days":
        foundSkuFba !== -1 ? masterResponse[foundSkuFba]["30 days"] : "#N/A",
      "15 Days":
        foundSkuFba !== -1 ? masterResponse[foundSkuFba]["15 days"] : "#N/A",
      "7 Days":
        foundSkuFba !== -1 ? masterResponse[foundSkuFba]["7 days"] : "#N/A",
      "3 Days":
        foundSkuFba !== -1 ? masterResponse[foundSkuFba]["3 days"] : "#N/A",
    };
  });

  secondArray = secondArray.map((item) => {
    const foundAsin = masterAsinIndex.indexOf(item["ASIN"]);
    const foundSkuSnl = masterSkuIndex.indexOf(item["SNL SKU"]);

    return {
      ...item,
      "Aged Inventory": item["ASIN"]
        ? foundAsin !== -1
          ? masterResponse[foundAsin]["Total ageing Inventory"]
          : "#N/A"
        : "",
      "30 Days":
        foundSkuSnl !== -1 ? masterResponse[foundSkuSnl]["30 days"] : "#N/A",
      "15 Days":
        foundSkuSnl !== -1 ? masterResponse[foundSkuSnl]["15 days"] : "#N/A",
      "7 Days":
        foundSkuSnl !== -1 ? masterResponse[foundSkuSnl]["7 days"] : "#N/A",
      "3 Days":
        foundSkuSnl !== -1 ? masterResponse[foundSkuSnl]["3 days"] : "#N/A",
    };
  });

  console.log(
    `Time elapsed - Master File: ${(
      (new Date().getTime() - date5.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  FBA INV FILE PROCESSING
  const date2 = new Date();

  let fbaIndex = fbaInvResponse.map((el) => el["sku"]);
  firstArray = firstArray.map((item) => {
    const found = fbaIndex.indexOf(item["FBA SKU"]);

    return {
      ...item,
      Stock:
        found !== -1
          ? Number(fbaInvResponse[found]["afn-fulfillable-quantity"])
          : "#N/A",
      Reserve:
        found !== -1
          ? Number(fbaInvResponse[found]["afn-reserved-quantity"])
          : "#N/A",
      Inbound:
        found !== -1
          ? Number(fbaInvResponse[found]["afn-inbound-working-quantity"]) +
            Number(fbaInvResponse[found]["afn-inbound-shipped-quantity"]) +
            Number(fbaInvResponse[found]["afn-inbound-receiving-quantity"])
          : "#N/A",
    };
  });

  secondArray = secondArray.map((item) => {
    const found = fbaIndex.indexOf(item["SNL SKU"]);

    return {
      ...item,
      Stock:
        found !== -1
          ? Number(fbaInvResponse[found]["afn-fulfillable-quantity"])
          : "#N/A",
      Reserve:
        found !== -1
          ? Number(fbaInvResponse[found]["afn-reserved-quantity"])
          : "#N/A",
      Inbound:
        found !== -1
          ? Number(fbaInvResponse[found]["afn-inbound-working-quantity"]) +
            Number(fbaInvResponse[found]["afn-inbound-shipped-quantity"]) +
            Number(fbaInvResponse[found]["afn-inbound-receiving-quantity"])
          : "#N/A",
    };
  });

  console.log(
    `Time elapsed - FBA Inv File: ${(
      (new Date().getTime() - date2.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  SNL INV FILE PROCESSING
  const date3 = new Date();

  let snlIndex = snlInvResponse.map((el) => el["SKU"]);
  firstArray = firstArray.map((item) => {
    const found = snlIndex.indexOf(item["FBA SKU"]);

    return {
      ...item,
      "SNL INV":
        found !== -1
          ? Number(snlInvResponse[found]["Inventory in SnL FC (units)"])
          : "#N/A",
    };
  });

  secondArray = secondArray.map((item) => {
    const found = snlIndex.indexOf(item["SNL SKU"]);

    return {
      ...item,
      "SNL INV":
        found !== -1
          ? Number(snlInvResponse[found]["Inventory in SnL FC (units)"])
          : "#N/A",
    };
  });

  console.log(
    `Time elapsed - SNL Inv File: ${(
      (new Date().getTime() - date3.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  LIFE SALES FILE PROCESSING
  const date7 = new Date();

  if (lifeSalesResponse1 && lifeSalesResponse2 && lifeSalesResponse3) {
    const lifeSalesResponseIndex1 = lifeSalesResponse1.map((el) => el["SKU"]);
    const lifeSalesResponseIndex2 = lifeSalesResponse2.map((el) => el["SKU"]);
    const lifeSalesResponseIndex3 = lifeSalesResponse3.map((el) => el["SKU"]);

    firstArray = firstArray.map((item) => {
      const found1 = lifeSalesResponseIndex1.indexOf(item["FBA SKU"]);
      const found2 = lifeSalesResponseIndex2.indexOf(item["FBA SKU"]);
      const found3 = lifeSalesResponseIndex3.indexOf(item["FBA SKU"]);

      return {
        ...item,
        "Lifetime Qty Sales":
          found1 !== -1
            ? lifeSalesResponse1[found1]["Sum of Sum of quantity"]
            : "#N/A",
        "SALES 2019":
          found2 !== -1
            ? lifeSalesResponse2[found2]["Sum of Sold qty (2019)"]
            : "#N/A",
        "Current Year Sales":
          found3 !== -1
            ? lifeSalesResponse3[found3]["Sum of Sum of quantity"]
            : "#N/A",
      };
    });

    secondArray = secondArray.map((item) => {
      const found1 = lifeSalesResponseIndex1.indexOf(item["SNL SKU"]);
      const found2 = lifeSalesResponseIndex2.indexOf(item["SNL SKU"]);
      const found3 = lifeSalesResponseIndex3.indexOf(item["SNL SKU"]);

      return {
        ...item,
        "Lifetime Qty Sales":
          found1 !== -1
            ? lifeSalesResponse1[found1]["Sum of Sum of quantity"]
            : "#N/A",
        "SALES 2019":
          found2 !== -1
            ? lifeSalesResponse2[found2]["Sum of Sold qty (2019)"]
            : "#N/A",
        "Current Year Sales":
          found3 !== -1
            ? lifeSalesResponse3[found3]["Sum of Sum of quantity"]
            : "#N/A",
      };
    });
  }

  console.log(
    `Time elapsed - Life Sales File: ${(
      (new Date().getTime() - date7.getTime()) /
      (1000 * 60)
    ).toFixed(2)} minutes`
  );

  // NOTE:  CONSTRUCTING OUTPUT FILE

  const firstArrayCopy = firstArray.map((item) => ({
    ...item,
  }));

  const secondArrayCopy = secondArray.map((item) => ({
    ...item,
  }));

  return {
    firstArray: firstArrayCopy,
    secondArray: secondArrayCopy,
  };
};

export default reorderFile;
