import XLSX from "xlsx";

import getHeaderRow from "./getHeaderRow";

const masterDataColumns = ["SKU", "Vendor Name", "ASIN", "Breakeven"];

const masterColumns = [
  "SKU",
  "Prefix",
  "Model#",
  "Vendor Name",
  "ASIN",
  "UPC",
  "Item Title",
  "Master Pack",
  "Inner Pack",
  "Gross Price",
  "Discount(%)",
  "Net Price",
  "Total Cost after adding extra charges",
  "Differencof Cost and Net price",
  "Item Brand",
  "SKU Status",
  "Last Modified by",
  "Inactivity End Date",
  "Remarks",
  "MOV AMOUNT",
  "MOV-FREE SHIPPING",
  "MOV DISCOUNT",
  "Loss Selling",
  "Lifetime Qty Sales",
  "SALES 2019",
  "Current year sales",
  "30 days",
  "15 days",
  "7 days",
  "3 days",
  "1 day",
  "value",
  "count",
  "BuyBox Price/Landed Price",
  "Prep Instruction",
  "AFN-FUL-QTY",
  "AFN-RSRVD-QTY",
  "AFN-INBND-WRKN-QTY",
  "AFN-INBND-SHPD-QTY",
  "AFN-INBND-RCVN-QTY",
  "Total Inventory",
  "OFFERS",
  "Parent Child Info",
  "ADDITIONAL INFO",
  "Total Cost",
  "FBA Cost",
  "Amz Commission %",
  "MAP",
  "MAP as per BE",
  "Floor",
  "Ceiling",
  "Listing Price",
  "Breakeven",
  "NEW BE AS PER COST VARIANCE",
  "P/L",
  "P/L%",
  "Inventory Age 0 To 90 Days",
  "Inventory Age 91 To 180 Days",
  "Inventory Age 181 To 270 Days",
  "Inventory Age 271 To 365 Days",
  "Inventory Age 365 PLUS Days",
  "Total ageing Inventory",
  "FOLLWING MAP",
  "Remarks Pricing",
  "LTSF SUM",
  "LTSF QTY",
];

const masterReportColumns = [
  ...masterColumns,
  "Scrapper Price",
  "Grand Total",
  "Sales Days",
  "Strike Rate",
  ">SR",
  "<SR",
  "Avg>SR",
  "Avg<SR",
  "OS",
  "CS",
  "AS",
  "Re-order Recommendation",
  "Quantity",
  "Amount",
];

const reorderColumns = ["SKU", "Remarks"];

const salesColumns = ["purchase-date", "sku", "quantity"];

const mfiColumns = ["sku", "asin", "afn-total-quantity"];

const snlcolumns = ["SKU", "Inventory in SnL FC (units)"];

const fbaReportColumns = [
  "ASIN",
  "Customer Reviews",
  "offers",
  "Sales Per Month",
  "Sales Rank",
];

const masterProductTableColumns = [
  "SKU",
  "Prefix",
  "Model#",
  "Vendor Name",
  "ASIN",
  "UPC",
  "Item Title",
  "Master Pack",
  "Inner Pack",
  "Gross Price",
  "Discount(%)",
  "Net Price",
  "Item Brand",
  "SKU Status",
  "Last Modified by",
  "Inactivity End Date",
  "Remarks",
  "MOV AMOUNT",
  "MOV-FREE SHIPPING",
  "MOV DISCOUNT",
  "Loss Selling",
  "Lifetime Qty Sales",
  "Lifetime Sales($)",
  "Current Year Sales",
  "Qty Sales(365)",
  "Qty Sales(180)",
  "Qty Sales(90)",
  "Qty Sales(60)",
  "Qty Sales(30)",
  "Qty Sales(15)",
  "Qty Sales(7)",
  "Qty Sales(3)",
  "Qty Sales(1)",
  "AFN-FUL-QTY",
  "AFN-RSRVD-QTY",
  "AFN-INBND-WRKN-QTY",
  "AFN-INBND-SHPD-QTY",
  "AFN-INBND-RCVN-QTY",
  "Inventory Age 0 To 90 Days",
  "Inventory Age 91 To 180 Days",
  "Inventory Age 181 To 270 Days",
  "Inventory Age 271 To 365 Days",
  "Inventory Age 365 PLUS Days",
  "Total Cost",
  "FBA Cost",
  "Amz Commission %",
  "Floor",
  "Ceiling",
  "Breakeven",
  "MAP",
  "Sales Rank",
  "Product Category Id",
  "BuyBox Price/Landed Price",
  "Prep Instruction",
  "Selling Price",
];

const inventoryColumns = [
  "condition",
  "sku",
  "asin",
  "product-name",
  "your-price",
  "afn-fulfillable-quantity",
  "afn-reserved-quantity",
  "afn-inbound-working-quantity",
  "afn-inbound-shipped-quantity",
  "afn-inbound-receiving-quantity",
];

const lifeTimeSalesColumns = ["SKU", "Sum of Sum of quantity"];
const lifeTimeSalesColumns2 = ["SKU", "Sum of Sold qty (2019)"];
const lifeTimeSalesColumns3 = ["SKU", "Sum of Sum of quantity"];

const feePreviewColumns = [
  "sku",
  "currency",
  "estimated-pick-pack-fee-per-unit",
  "expected-fulfillment-fee-per-unit",
];

const fbaFeePreviewColumns = ["sku", "fba fees"];

const vendorColumns = ["PREFIX", "OFFERS", "ADDITIONAL INFO"];

const beColumns = [
  "SKU",
  "Total Cost",
  "FBA Cost",
  "Amazon Commission %",
  "Floor",
  "Ceiling",
  "MAP",
];

const snlAndPricingColumns = [
  "FBA SKU",
  "Cost/Unit",
  "Fulfilment Cost Sub Total",
  "Amazon Commission %",
  "Min Price",
  "Ceiling",
  "MAP",
];

const previouMasterReportColumns = [
  "SKU",
  "Total Cost",
  "FBA Cost",
  "Amz Commission %",
  "Floor",
  "Ceiling",
  "Remarks Pricing",
  "MAP",
];

const healthColumns = [
  "sku",
  "currency",
  "condition",
  "qty-to-be-charged-ltsf-12-mo",
  "qty-to-be-charged-ltsf-6-mo",
  "projected-ltsf-12-mo",
  "projected-ltsf-6-mo",
  "inv-age-0-to-90-days",
  "inv-age-91-to-180-days",
  "inv-age-181-to-270-days",
  "inv-age-271-to-365-days",
  "inv-age-365-plus-days",
];

const violationColumns = ["Prefix", "remarks"];

const orderColumns = ["purchase-date", "sku", "quantity"];

const master_report_columns = [
  "SKU",
  "ASIN",
  "Total ageing Inventory",
  "30 days",
  "15 days",
  "7 days",
  "3 days",
];
const fba_inv_columns = [
  "sku",
  "afn-fulfillable-quantity",
  "afn-reserved-quantity",
  "afn-inbound-working-quantity",
  "afn-inbound-shipped-quantity",
  "afn-inbound-receiving-quantity",
];
const snl_inv_columns = ["SKU", "Inventory in SnL FC (units)"];
const archive_inv_columns = ["asin-atz", "sku-atz", "asin-zta", "sku-zta"];
const pmt_export_columns = ["SKU", "Prefix", "ASIN", "SKU Status", "Remarks"];
const restricted_brand_columns = ["Restricted Brands"];
const scrapper_output_columns = [
  "ItemCode",
  "ASIN",
  "Item eligible for SNL(Yes/No)",
  "Brand",
];

const sheetCleanUp = (acceptedFiles, name, callBack, setUpload) => {
  if (!acceptedFiles[0]) {
    alert(
      "Please upload only supported file formats such as .xlsx, .xls and .ods"
    );

    return;
  }
  setUpload(true);
  let reader = new FileReader();
  let missingColumns = [];
  let sheetToJson = [];

  reader.readAsBinaryString(acceptedFiles[0]);
  reader.onloadend = (e) => {
    let data = e.target.result;
    let workbook = XLSX.read(data, {
      type: "binary",
    });
    let worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const sheetHeaders = getHeaderRow(worksheet);

    if (name === "FBA Fee Preview Master*") {
      sheetToJson = XLSX.utils.sheet_to_json(worksheet, {
        raw: false,
        cellDates: true,
      });
    } else {
      sheetToJson = XLSX.utils.sheet_to_json(worksheet, {
        raw: true,
        cellDates: true,
      });
    }

    let worksheet2, worksheet3;
    let sheetHeaders2 = [],
      sheetHeaders3 = [];
    let sheetToJson2 = [],
      sheetToJson3 = [];

    if (
      name === "Lifetime Sales File" &&
      workbook.SheetNames[1] &&
      workbook.SheetNames[2]
    ) {
      worksheet2 = workbook.Sheets[workbook.SheetNames[1]];
      sheetHeaders2 = getHeaderRow(worksheet2);
      sheetToJson2 = XLSX.utils.sheet_to_json(worksheet2, {
        raw: true,
        cellDates: true,
      });

      worksheet3 = workbook.Sheets[workbook.SheetNames[2]];
      sheetHeaders3 = getHeaderRow(worksheet3);
      sheetToJson3 = XLSX.utils.sheet_to_json(worksheet3, {
        raw: true,
        cellDates: true,
      });
    }

    switch (name) {
      case "Master Sheet":
        masterDataColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Master File":
        masterColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Reorder File":
        reorderColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Sales File":
        salesColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "MFI File":
        mfiColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "SNL File":
        snlcolumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Master Report":
        masterReportColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "FBA Toolkit Report":
        fbaReportColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Product Master Table*":
        masterProductTableColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Manage Inventory File*":
        inventoryColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Lifetime Sales File":
        lifeTimeSalesColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(`Sheet 1 - ${item}`);
          }
        });

        lifeTimeSalesColumns2.forEach((item) => {
          if (!sheetHeaders2.includes(item)) {
            missingColumns.push(`Sheet 2 - ${item}`);
          }
        });

        lifeTimeSalesColumns3.forEach((item) => {
          if (!sheetHeaders3.includes(item)) {
            missingColumns.push(`Sheet 3 - ${item}`);
          }
        });
        break;
      case "Fee Preview Report*":
        feePreviewColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "FBA Fee Preview Master*":
        fbaFeePreviewColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Manage Vendor File*":
        vendorColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "BE Module File*":
        beColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Master SNL File*":
        snlAndPricingColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "7 Day Pricing File*":
        snlAndPricingColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Previous Master Report*":
        previouMasterReportColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Inventory Health File":
        healthColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Map Violation Vendor File":
        violationColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "All Order File":
        orderColumns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Master Report File*":
        master_report_columns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "FBA Inventory File*":
        fba_inv_columns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "SNL Inventory File*":
        snl_inv_columns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Archive Inventory File*":
        archive_inv_columns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "PMT Export File*":
        pmt_export_columns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Restricted Brand File*":
        restricted_brand_columns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      case "Scrapper Output File*":
        scrapper_output_columns.forEach((item) => {
          if (!sheetHeaders.includes(item)) {
            missingColumns.push(item);
          }
        });
        break;
      default:
        return true;
    }

    setUpload(false);

    callBack({
      fileName: acceptedFiles[0].name,
      missingColumns,
      sheetToJson,
      sheetToJson2,
      sheetToJson3,
    });
  };
};

export default sheetCleanUp;
