import XLSX from "xlsx";

const createOutputSheets = (data, data2, file, sheetName, sheetName2) => {
  const fileName = `${file}.xlsx`;

  const ws = XLSX.utils.json_to_sheet(data);
  const ws2 = XLSX.utils.json_to_sheet(data2);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.utils.book_append_sheet(wb, ws2, sheetName2);

  XLSX.writeFile(wb, fileName, {});
};

export default createOutputSheets;
