import React from "react";
import { Grid, Chip, Button, Menu, MenuItem } from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import DoneIcon from "@material-ui/icons/Done";

const UploadFeedBack = ({ responseObject }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    return (
        <Grid
            container
            alignItems='center'
            direction='column'
            spacing={1}
            style={{ marginTop: "10px" }}>
            {responseObject ? (
                responseObject.missingColumns.length < 1 ? (
                    <Grid item>
                        <Chip icon={<DoneIcon />} label={responseObject.fileName} color='primary' />
                    </Grid>
                ) : (
                    <>
                        <Grid item>
                            <Chip
                                icon={<BlockIcon />}
                                label={responseObject.fileName}
                                color='secondary'
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant='outlined'
                                color='secondary'
                                onClick={(event) => setAnchorEl(event.currentTarget)}>
                                Missing Columns
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={(event) => setAnchorEl(null)}>
                                {responseObject.missingColumns.map((item) => (
                                    <MenuItem key={item}>{item}</MenuItem>
                                ))}
                            </Menu>
                        </Grid>
                    </>
                )
            ) : null}
        </Grid>
    );
};

export default UploadFeedBack;
